import axios, { AxiosRequestConfig } from 'axios'
import { User } from '../models/user';
import { AddCampaignModel, CampaignModel } from '../models/campaing';
import { CampaignSummaryModel } from '../models/campaing-summary';
import { CampaignStepsSummary } from '../models/campaign-steps-summary';
import { AdsSummaryModel } from '../models/ads-summary';
import { OptimizationData } from '../models/optimization-data';
import { CampaignSiteSummaryModel } from '../models/campaign-sites-summary';
import { AccountTaboolaDataModel } from '../models/account-taboola-data';
import { AddWaitListModel, WaitListModel } from '../models/wait-list';

export const config: AxiosRequestConfig = {
	//baseURL: 'http://18.230.209.192:5050/api/',
	baseURL: 'https://oracclum.com:5050/api/',
	//baseURL: process.env.REACT_APP_API_URL,
  	//baseURL: 'http://localhost:5050/api/',
	responseType: 'json',
	headers: {
		accept: 'application/json',
		'content-type': 'application/json',
	},
	timeout: 50000,
	withCredentials: false,
	maxRedirects: 5,
	validateStatus: (status: number) => status >= 200 && status < 300,
	transformResponse: (response) => {
		if (response !== null && response.error) {
			console.log(response.error);
		}
		let resp;
		try {
			resp = JSON.parse(response);
		} catch {
			resp = response;
		}
		return resp;
	},
};

export const login = async (userInfo: any) => {
	config.url = 'login'
	config.method = 'post'
	config.data = userInfo
	config.signal = undefined

	return axios.request<any>(config);
};

export const logout = async (accessToken: string) => {
	config.url = 'logout'
	config.method = 'post'
	config.data = {accessToken: accessToken}
	config.signal = undefined

	return axios.request<any>(config);
};

export const changePass = async (accessToken: string, newPass: string) => {
	config.url = 'change-pass'
	config.method = 'post'
	config.data = {newPass: newPass}
	config.signal = undefined

	return axios.request<any>(config);
};

export const loadUserData = async (accessToken: string) => {
	config.url = `loadUserData/${accessToken}`
	config.method = 'get'
	config.signal = undefined

	return axios.request<User>(config)
}

export const loadAccountTaboolaData = async (accessToken: string) => {
	config.url = `load-taboola-info/${accessToken}`
	config.method = 'get'
	config.signal = undefined

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<AccountTaboolaDataModel>(config)
}

export const addCampaign = async (newCampaign: AddCampaignModel, accessToken: string)  => {
	config.url = 'add-campaign'
	config.method = 'post'
	config.data = newCampaign
	config.signal = undefined

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}

	return axios.request<CampaignModel>(config)
}

export const deleteCampaign = async (idCampaign: number, accessToken: string)  => {
	config.url = 'delete-campaign'
	config.method = 'post'
	config.data = {idCampaign: idCampaign}
	config.signal = undefined

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}

	return axios.request<CampaignModel>(config)
}

export const loadUserCampaings = async (accessToken: string, controller: AbortController) => {
	config.url = `load-user-campaigns`
	config.method = 'get'
	config.signal = controller.signal

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<CampaignSummaryModel[]>(config)
}

export const loadFunnelsByTime = async (accessToken: string, id_campaign: number, days: number, controller: AbortController) => {
	config.url = `load-campaign-steps-by-time/${id_campaign}/${days}`
	config.method = 'get'
	config.signal = controller.signal

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<CampaignStepsSummary>(config)
}

export const loadCampaignOptimizationData = async (accessToken: string, id_campaign: number, days: number, controller: AbortController) => {
	config.url = `load-campaign-optimization-data/${id_campaign}/${days}`
	config.method = 'get'
	config.signal = controller.signal

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<CampaignModel>(config)
}

export const loadAdsOptimizationData = async (accessToken: string, id_campaign: number, days: number, controller: AbortController) => {
	config.url = `load-ads-summary/${id_campaign}/${days}`
	config.method = 'get'
	config.signal = controller.signal

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<AdsSummaryModel[]>(config)
}

export const loadOneAdOptimizationData = async (accessToken: string, id_campaign: number, id_taboola_ads: number, days: number, controller: AbortController) => {
	config.url = `load-ad-summary/${id_campaign}/${id_taboola_ads}/${days}`
	config.method = 'get'
	config.signal = controller.signal

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<OptimizationData>(config)
}

export const loadCampaignSitesOptimizationData = async (accessToken: string, id_campaign: number, days: number, controller: AbortController) => {
	config.url = `load-campaign-sites-summary/${id_campaign}/${days}`
	config.method = 'get'
	config.signal = controller.signal

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<CampaignSiteSummaryModel[]>(config)
}

export const loadOneSiteOptimizationData = async (accessToken: string, id_campaign: number, id_site: number, days: number, controller: AbortController) => {
	config.url = `load-one-site-summary/${id_campaign}/${id_site}/${days}`
	config.method = 'get'
	config.signal = controller.signal

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<OptimizationData>(config)
}

export const addTaboolaInfo = async (accessToken: string, taboolaInfo: any) => {
	config.url = 'add-taboola-info'
	config.method = 'post'
	config.data = taboolaInfo
	config.signal = undefined

	if (config.headers) {
		Object.assign(config.headers, { 'x-access-token': accessToken })
	}
	return axios.request<any>(config);
}

export const registerToWaitList = async (data: AddWaitListModel) => {
	config.url = 'add-wait-list'
	config.method = 'post'
	config.data = data
	config.signal = undefined

	return axios.request<WaitListModel>(config);
}