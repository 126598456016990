import { useState } from 'react'
import Button from '../../FormElements/Button'
import { ContainerEditCampaign, HeaderNewCampaign, ContainerEditCampaignForm, InputContainer, InputLabel, InputNumber } from './styles'
import Loading from '../../Loading'
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { AccountTaboolaDataModel } from '../../../models/account-taboola-data'
import { updateTaboolaCampaign } from '../../../services/taboola'
import { CampaignTaboolaData } from '../../../models/campaign-taboola-data'

interface Props {
    taboolaInfo?: AccountTaboolaDataModel[]
    idCampaign: number
    campaignTaboolaData: CampaignTaboolaData
    updateCampaignTaboolaData: (data: CampaignTaboolaData) => void
    editTaboolaStatusMessage: (status: boolean) => void
}

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));
const label = { inputProps: { 'aria-label': 'controlled' } };


const ModalEditTaboolaCampaign = ({ taboolaInfo, idCampaign, campaignTaboolaData, updateCampaignTaboolaData, editTaboolaStatusMessage }: Props) => {
    const [loading, setLoading] = useState<Boolean>(false)
    const [newCpc, setNewCpc] = useState<number>(campaignTaboolaData.cpc)
    const [newDailyCap, setNewDailyCap] = useState<number>(campaignTaboolaData.daily_cap)
    const [campaignActive, setCampaignActive] = useState<boolean>(campaignTaboolaData.active)


    const submitEdit = () => {
        const updateData = {
            daily_cap: newDailyCap,
            cpc: newCpc,
            is_active: campaignActive
        }
        if (taboolaInfo) {
            setLoading(true)
            updateTaboolaCampaign(taboolaInfo[0].access_token, taboolaInfo[0].account_id, String(idCampaign), updateData)
            .then(resp => {
                updateCampaignTaboolaData({
                    ...campaignTaboolaData,
                    cpc: newCpc,
                    daily_cap: newDailyCap,
                    active: campaignActive
                })
                setLoading(false)
                const modal = document.getElementById('edit-taboola-modal')
                if (modal) modal.style.display = 'none'
                editTaboolaStatusMessage(true)
            })
            .catch(err => {
                editTaboolaStatusMessage(false)
                console.log(err)
            })
        }
    }

    const handleCpcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) <= newDailyCap) {
            setNewCpc(Number(e.target.value))
        } else {
            setNewCpc(newDailyCap)
        }
    }

    const closeEditModal = () => {
        const modal = document.getElementById('edit-taboola-modal')
        if (modal) {
          modal.style.display = 'none'
          setNewCpc(campaignTaboolaData.cpc)
          setCampaignActive(campaignTaboolaData.active)
          setNewDailyCap(campaignTaboolaData.daily_cap)
        }
      }

    return (
        <ContainerEditCampaign>
            <HeaderNewCampaign>Editar Campanha</HeaderNewCampaign>

            {loading? (
                <Loading />
            ) : (
                <ContainerEditCampaignForm>
                    <InputContainer>
                        <InputLabel>Status:</InputLabel>
                        <GreenSwitch {...label} checked={campaignActive} onChange={(e) => setCampaignActive(e.target.checked)} />
                    </InputContainer>

                    <InputContainer>
                        <InputLabel>Limite diário: </InputLabel>
                        <InputNumber type='number' step={0.01} min={0.01} value={newDailyCap} onChange={(e) => setNewDailyCap(Number(e.target.value))} />
                    </InputContainer>

                    <InputContainer>
                        <InputLabel>CPC: </InputLabel>
                        <InputNumber type='number' step={0.001} min={0.01} max={newDailyCap} value={newCpc} onChange={(e) => handleCpcChange(e)} />
                    </InputContainer>

                    <div style={{padding: '15px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                        <Button type='button' size='medium' model='cancel' width='30%' text='Cancelar' mobileWidth='80%' onClick={() => closeEditModal()} />
                        <Button type='submit' size='medium' model='confirm' width='30%' text='Enviar' mobileWidth='80%' onClick={() => submitEdit()}/>
                    </div>
                </ContainerEditCampaignForm>
            )}
        </ContainerEditCampaign>
    )
}

export default ModalEditTaboolaCampaign