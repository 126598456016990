import { ContentContainer, AdsContainer} from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../../models/user'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { loadOneAdOptimizationData } from '../../../services'
import Loading from '../../../components/Loading'
import { OptimizationData } from '../../../models/optimization-data'
import AdRowByTime from '../../../components/AdRowByTime'

interface Props {
  user?: User []
}
interface OptimizationDataPeriod {
  today: OptimizationData | null 
  yesterday: OptimizationData | null
  last3: OptimizationData | null
  last7: OptimizationData | null
  last15: OptimizationData | null
  last30: OptimizationData | null
  last60: OptimizationData | null
  last90: OptimizationData | null
  allTime: OptimizationData | null
}

const AdTab = ({ user }: Props) => {
  const navigate = useNavigate()

  const { today, yesterday, last3, last7, last15, last30, last60, last90, allTime, dataLocation } = useOutletContext<any>();

  const [loading1, setLoading1] = useState<boolean>(true)
  const [loading2, setLoading2] = useState<boolean>(true)
  const [loading3, setLoading3] = useState<boolean>(true)
  const [loading4, setLoading4] = useState<boolean>(true)
  const [loading5, setLoading5] = useState<boolean>(true)
  const [loading6, setLoading6] = useState<boolean>(true)
  const [loading7, setLoading7] = useState<boolean>(true)
  const [loading8, setLoading8] = useState<boolean>(true)
  const [loading9, setLoading9] = useState<boolean>(true)

  const [adOptimizationData, setAdOptimizationData] = useState<OptimizationDataPeriod>({today: null, yesterday: null, last3: null, last7: null, last15: null, last30: null, last60: null, last90: null, allTime: null})

  useEffect(() => {
    const controller = new AbortController();
    if (user && today) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 0, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.today = resp.data
      setAdOptimizationData(adArray)
      setLoading1(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, today, dataLocation.campaignSummary, dataLocation.ad.id_ads_taboola])

  useEffect(() => {
    const controller = new AbortController();
    if (user && yesterday) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 1, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.yesterday = resp.data
      setAdOptimizationData(adArray)
      setLoading2(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, yesterday, dataLocation.campaignSummary, dataLocation.id_ads_taboola])

  useEffect(() => {
    const controller = new AbortController();
    if (user && last3) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 3, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.last3 = resp.data
      setAdOptimizationData(adArray)
      setLoading3(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, last3, dataLocation.campaignSummary, dataLocation.id_ads_taboola])

  useEffect(() => {
    const controller = new AbortController();
    if (user && last7) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 7, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.last7 = resp.data
      setAdOptimizationData(adArray)
      setLoading4(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, last7, dataLocation.campaignSummary, dataLocation.id_ads_taboola])

  useEffect(() => {
    const controller = new AbortController();
    if (user && last15) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 15, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.last15 = resp.data
      setAdOptimizationData(adArray)
      setLoading5(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, last15, dataLocation.campaignSummary, dataLocation.id_ads_taboola])

  useEffect(() => {
    const controller = new AbortController();
    if (user && last30) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 30, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.last30 = resp.data
      setAdOptimizationData(adArray)
      setLoading6(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, last30, dataLocation.campaignSummary, dataLocation.id_ads_taboola])

  useEffect(() => {
    const controller = new AbortController();
    if (user && last60) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 60, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.last60 = resp.data
      setAdOptimizationData(adArray)
      setLoading7(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, last60, dataLocation.campaignSummary, dataLocation.id_ads_taboola])

  useEffect(() => {
    const controller = new AbortController();
    if (user && last90) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 90, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.last90 = resp.data
      setAdOptimizationData(adArray)
      setLoading8(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, last90, dataLocation.campaignSummary, dataLocation.id_ads_taboola])

  useEffect(() => {
    const controller = new AbortController();
    if (user && allTime) {
      loadOneAdOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, dataLocation.ad.id_ads_taboola, 500, controller)
     .then(resp => {
      let adArray = adOptimizationData
      adArray.allTime = resp.data
      setAdOptimizationData(adArray)
      setLoading9(false)
     })
     .catch(err => {
      console.log(err)
     })
     return () => controller.abort();
    }
  }, [user, allTime, dataLocation.campaignSummary, dataLocation.id_ads_taboola])

  const redirectPage = () => {
    navigate('/campaigns/' + dataLocation.campaignSummary.id + '/ads/' + dataLocation.ad.id_ads_taboola + '/sites', {state: { campaignSummary: dataLocation.campaignSummary, ad: dataLocation.ad }} )
  }

  return (
    <ContentContainer>
      <AdsContainer>
        {today && (
          loading1? (
            <Loading />
          ) : (
            <AdRowByTime period='Hoje' data={adOptimizationData.today}/>
          )
        )}

        {yesterday && (
          loading2? (
            <Loading />
          ) : (
            <AdRowByTime period='Ontem' data={adOptimizationData.yesterday}/>
          )
        )}

        {last3 && (
          loading3? (
            <Loading />
          ) : (
            <AdRowByTime period='3 Dias' data={adOptimizationData.last3}/>
          )
        )}

        {last7 && (
          loading4? (
            <Loading />
          ) : (
            <AdRowByTime period='7 Dias' data={adOptimizationData.last7}/>
          )
        )}

        {last15 && (
          loading5? (
            <Loading />
          ) : (
            <AdRowByTime period='15 Dias' data={adOptimizationData.last15}/>
          )
        )}

        {last30 && (
          loading6? (
            <Loading />
          ) : (
            <AdRowByTime period='30 Dias' data={adOptimizationData.last30}/>
          )
        )}

        {last60 && (
          loading7? (
            <Loading />
          ) : (
            <AdRowByTime period='60 Dias' data={adOptimizationData.last60}/>
          )
        )}

        {last90 && (
          loading8? (
            <Loading />
          ) : (
            <AdRowByTime period='90 Dias' data={adOptimizationData.last90}/>
          )
        )}

        {allTime && (
          loading9? (
            <Loading />
          ) : (
            <AdRowByTime period='Vitalício' data={adOptimizationData.allTime}/>
          )
        )}
      </AdsContainer>
    </ContentContainer>
  )
}

export default AdTab