import Dexie, { Table } from 'dexie'
import { User } from '../models/user'
import { AccountTaboolaDataModel } from '../models/account-taboola-data'

export class MySubClassedDixie extends Dexie {
  user!: Table<User>
  taboola!: Table<AccountTaboolaDataModel>

  constructor() {
    super('oracclumdb')
    this.version(4).stores({
      user: '++id, name, email, userType, access_token',
      taboola: 'account_id, client_id, client_secret, access_token'
    })
  }
}

export const db = new MySubClassedDixie()
