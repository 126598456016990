import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { User } from '../models/user'
import Layout from '../pages/Layout'
import LoginPage from '../pages/Login'
import CampaingsPage from '../pages/Campaings'
import CampaingFunnelsPage from '../pages/Campaing/Funnels'
import CampaingAdsPage from '../pages/Campaing/Ads'
import AdPageLayout from '../pages/Ad'
import AdTab from '../pages/Ad/AdTab'
import CampaingSitesPage from '../pages/Campaing/Sites'
import SettingsPage from '../pages/Settings'
import LandingPage from '../pages/Landing'
import IntegrationsPage from '../pages/Integrations'
import PoliciesPage from '../pages/Policies'
import UseTermsPage from '../pages/UseTerms'
import CampaignPageLayout from '../pages/Campaing'
import { AccountTaboolaDataModel } from '../models/account-taboola-data'

interface Props {
	user?: User []
	taboolaInfo?: AccountTaboolaDataModel[]
}

const AppRoutes = ({ user, taboolaInfo }: Props) => {
	return (
		<BrowserRouter>
			<Routes>				
				<Route path="/" element={<Layout user={user} taboolaInfo={taboolaInfo}/>}>
					<Route path='login' element={<LoginPage/>} />
					<Route path='landing' element={<LandingPage/>} />
					<Route path='policies' element={<PoliciesPage/>} />
					<Route path='use-terms' element={<UseTermsPage/>} />

					<Route path='campaigns' element={<CampaingsPage user={user} taboolaInfo={taboolaInfo}/>} />
					<Route path='settings' element={<SettingsPage user={user}/>} />
					<Route path='integrations' element={<IntegrationsPage user={user} taboolaInfo={taboolaInfo}/>} />
					<Route path='campaign/:idCampaign/funnels' element={<CampaingFunnelsPage user={user}/>} />
					<Route path='campaign/:idCampaign/ads' element={<CampaingAdsPage user={user}/>} />
					<Route path='campaign/:idCampaign/sites' element={<CampaingSitesPage user={user}/>} />
					
					<Route path='campaigns/:idCampaign/' element={<CampaignPageLayout user={user} taboolaInfo={taboolaInfo}/>}>
						<Route path='funnels' element={<CampaingFunnelsPage user={user}/>} />
						<Route path='ads' element={<CampaingAdsPage user={user} taboolaInfo={taboolaInfo}/>} />
						<Route path='sites' element={<CampaingSitesPage user={user} taboolaInfo={taboolaInfo}/>} />
					</Route>

					<Route path='campaigns/:idCampaign/ads/:idAds/' element={<AdPageLayout user={user}/>}>
						<Route path='summary' element={<AdTab user={user}/>} />
					</Route>
					<Route path='*' element={<LoginPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default AppRoutes;
