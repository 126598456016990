import { CampaingLayoutPageContainer } from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../models/user'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import HeaderPage from './Header/index'
import { loadAccountTaboolaData, loadCampaignOptimizationData } from '../../services'
import { CampaignModel } from '../../models/campaing'
import { db } from '../../db/db'
import { getTaboolaCampaignData } from '../../services/taboola'
import { AccountTaboolaDataModel } from '../../models/account-taboola-data'
import { CampaignTaboolaData } from '../../models/campaign-taboola-data'
import ModalEditTaboolaCampaign from '../../components/Modals/EditTaboolaCampaign'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

interface Props {
  user?: User []
  taboolaInfo?: AccountTaboolaDataModel[]
}

const CampaignPageLayout = ({ user, taboolaInfo }: Props) => {
  const navigate = useNavigate()
  const location = useLocation();
  const dataLocation = location.state;

  const [pickDate, setPicDate] = useState<number>(dataLocation.pickDate? dataLocation.pickDate : 0)
  const [loading, setLoading] = useState<boolean>(true)
  const [optimizationData, setOptimizationData] = useState<CampaignModel>()
  const [campaignTaboolaData, setCampaignTaboolaData] = useState<CampaignTaboolaData>()

  useEffect(() => {
    const controller = new AbortController();
    if (user && user.length>0) {
      loadCampaignOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, pickDate, controller)
      .then(resp => {
        setOptimizationData(resp.data)
        setLoading(false)
      })
      .catch(err => {
        if (err.response.status === 403) {
          db.taboola.clear()
          db.user.clear()
          navigate('/login')
        }
        console.log(err)
      }) 
    }
  }, [user, pickDate, navigate, dataLocation])

  useEffect(() => {
    const controller = new AbortController();
    if (dataLocation.campaignSummary.id_campaign_taboola && taboolaInfo && optimizationData) {
      const acc_id = optimizationData.sub_account? optimizationData.sub_account : taboolaInfo[0].account_id
      getTaboolaCampaignData(acc_id, dataLocation.campaignSummary.id_campaign_taboola, taboolaInfo[0].access_token, controller)
      .then(resp => {
        const { cpc, daily_cap, approval_state, is_active, publisher_bid_modifier, publisher_targeting} = resp.data
        setCampaignTaboolaData({
          cpc: cpc,
          daily_cap: daily_cap,
          status: approval_state,
          active: is_active,
          bid_modifier: publisher_bid_modifier.values,
          blocked_publisher_targeting: publisher_targeting.value
        })
      })
      .catch(err => {
        console.log(err)
        if (err.response.status === 401 && user){
          loadAccountTaboolaData(user[0].access_token)
          .then(resp => {
            db.taboola.update(taboolaInfo[0].account_id, resp.data)
          })
        }
      })
    }
  }, [dataLocation, taboolaInfo, user, optimizationData])

  const selectClick = (selectedPeriod: number) => {
    setPicDate(selectedPeriod)
    if (selectedPeriod !== pickDate) setLoading(true)
  }

  const openEditModal = () => {
    const modal = document.getElementById('edit-taboola-modal')
    if (modal) {
      console.log('here')
      if (modal.style.display === 'flex') modal.style.display = 'none'
      else modal.style.display = 'flex'
    }
  }

  const updateCampaignTaboolaData = (data: CampaignTaboolaData): boolean => {
    setCampaignTaboolaData(data)
    return true
  }

  const editTaboolaStatusMessage = (status: boolean) => {
    if (status) toast.success('Campanha editada com sucesso!')
    else toast.error('Tivemos um problema, tente novamente mais tarde!')
  }

  const editAdStatusMessage = (status: boolean) => {
    if (status) toast.success('Ad editado com sucesso!')
    else toast.error('Tivemos um problema, tente novamente mais tarde!')
  }

  const editSiteStatusMessage = (status: boolean) => {
    if (status) toast.success('Site editado com sucesso!')
    else toast.error('Tivemos um problema, tente novamente mais tarde!')
  }

  return (
    <CampaingLayoutPageContainer>
      <HeaderPage campaign={optimizationData} pickDate={pickDate} campaignTaboolaData={campaignTaboolaData} selectClick={selectClick} openEditModal={openEditModal}/>

      <Outlet context={{ pickDate, dataLocation, loading, optimizationData, campaignTaboolaData, updateCampaignTaboolaData, editSiteStatusMessage, editAdStatusMessage }}/>

      <div id='edit-taboola-modal' className='modal'>
        {campaignTaboolaData && <ModalEditTaboolaCampaign taboolaInfo={taboolaInfo} idCampaign={dataLocation.campaignSummary.id_campaign_taboola} campaignTaboolaData={campaignTaboolaData} updateCampaignTaboolaData={updateCampaignTaboolaData} editTaboolaStatusMessage={editTaboolaStatusMessage}/>}
      </div>

      <ToastContainer />
    </CampaingLayoutPageContainer>
  )
}

export default CampaignPageLayout