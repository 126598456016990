import styled from 'styled-components'

export const ContainerNewCampaign = styled.div`
    max-height: 85vh;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;

    @media screen and (max-width: 750px) {
        width: 90%;
        max-height: 75vh;
    }
`

export const HeaderNewCampaign = styled.div`
    width: 100%;
    font-size: 5vh;
    font-weight: 200;
    height: 10vh;
    background-color: #dfdfdf;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #343434;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ContainerNewCampaignForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 90%;
    overflow: scroll;
`

export const MessageSuccess = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 15px;
`

export const ContainerInputs = styled.div`
    borderBottom: 1px solid #dfdfdf;
    display: flex;
    justifyContent: center;
    alignItems: center;
    gap: 15px;

     @media screen and (max-width: 750px) {
        flex-direction: column;
    }
`

export const SubAccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`

export const SubAccountSelect = styled.select`
    border: 1px solid #cecece;
    border-radius: 5px;
    font-size: 14px;
    min-height: 20px;
    padding: 10px;
`

export const SubAccountOption = styled.option`

`

export const SubAccountLabel = styled.div`
    font-size: 14px;
    font-family: Roboto;
    font-weight: medium;
    margin: 0 0 10px 0;
    color: #1e1e1e;
    letter-spacing: 0px;
`

