import { AdsLine, Period, OptimizationContainer, OptimizationInfo } from "./styles"
import { OptimizationData } from "../../models/optimization-data"

interface Props {
    period: string
    data: OptimizationData | null
}

const AdRowByTime = ({ data, period }: Props) => {

    return (
        <AdsLine>
            <Period>{period}</Period>
            <OptimizationContainer>
                <OptimizationInfo>Fatur. <strong>{data?.revenue.toFixed(2).replace('.', ',')}</strong></OptimizationInfo>
                <OptimizationInfo>Gasto <strong>{data?.expenses.toFixed(2).replace('.', ',')}</strong></OptimizationInfo>
                <OptimizationInfo>Clicks <strong>{data?.clicks}</strong></OptimizationInfo>
                <OptimizationInfo>Check. <strong>{data?.checkout}</strong></OptimizationInfo>
                <OptimizationInfo>Conver. <strong>{data?.sales}</strong></OptimizationInfo>
                <OptimizationInfo>cpc <strong>{data?.cpc.toFixed(2).replace('.', ',')}</strong></OptimizationInfo>
                <OptimizationInfo>vcpm <strong>{data?.vcpm.toFixed(2).replace('.', ',')}</strong></OptimizationInfo>
                <OptimizationInfo>cpa <strong>{data?.cpa? data?.cpa.toFixed(2).replace('.', ',') : '0,00' }</strong></OptimizationInfo>
                <OptimizationInfo>vctr <strong>{data?.vctr.toFixed(2).replace('.', ',')}%</strong></OptimizationInfo>
                <OptimizationInfo>ROAS <strong>{data? (data.roas*100).toFixed(2).replace('.', ','): 0.00}%</strong></OptimizationInfo>
            </OptimizationContainer>
        </AdsLine>
    )
}

export default AdRowByTime
