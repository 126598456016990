import styled from "styled-components";

export const SettingsPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #dfdfdf;
  gap: 20px;
  overflow: auto;
  box-shadow: 0px 0px 5px 1px black;

  @media screen and (max-width: 750px) {
    padding: 10px;
    padding-bottom: 50px;
  }
`

export const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding-bottom: 30px;
`

export const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
`