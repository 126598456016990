import { Container, CardPlan, CardHeader, CardTitle, CardText, PlanGroupBtn, CardsContainer, PriceImg, RegisterBtn } from './styles'
import { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import plan1 from '../../../assets/plano1.png'
import plan2 from '../../../assets/plano2.png'
import plan3 from '../../../assets/plano3.png'
import plan4 from '../../../assets/plano4.png'
import plan5 from '../../../assets/plano5.png'
import plan6 from '../../../assets/plano6.png'
import plan7 from '../../../assets/plano7.png'
import plan8 from '../../../assets/plano8.png'
import plan9 from '../../../assets/plano9.png'

interface Props {
  id: string
  openWaitListModal: () => void
}
const PriceSection = ({ id, openWaitListModal }: Props) => {
  const [compToShow, setCompToShow] = useState<string>('begg')

  const handleClick = (id: string) => {
    document.getElementById('begg')?.classList.remove('active')
    document.getElementById('interm')?.classList.remove('active')
    document.getElementById('advanc')?.classList.remove('active')
    const elem = document.getElementById(id)
    if (elem) {
      elem.classList.add('active')
      setCompToShow(id)
    }
  }

  return (
    <Container id={id}>
      <div style={{textAlign: 'center'}}>
        Planos e benefícios
      </div>

      <div style={{display: 'flex', justifyContent: 'center', gap: '10px', paddingTop: '30px'}}>
        <PlanGroupBtn id='begg' className='active' onClick={() => handleClick('begg')}>Iniciante</PlanGroupBtn>
        <PlanGroupBtn id='interm' onClick={() => handleClick('interm')}>Intermediário</PlanGroupBtn>
        <PlanGroupBtn id='advanc' onClick={() => handleClick('advanc')}>Avançado</PlanGroupBtn>
      </div>

      {compToShow === 'begg' && (
        <CardsContainer>
          <CardPlan>
            <CardHeader>
              <CardTitle>Inicial</CardTitle>
              <PriceImg src={plan1}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              1 Campanha trackeada
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              15 mil clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              1 mês de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>

          <CardPlan>
            <CardHeader>
              <CardTitle>Básico</CardTitle>
              <PriceImg src={plan2}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              3 Campanhas trackeadas
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              50 mil clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              6 meses de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>

          <CardPlan>
            <CardHeader>
              <CardTitle>Crescente</CardTitle>
              <PriceImg src={plan3}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              5 Campanhas trackeadas
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              85 mil clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              6 meses de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>
        </CardsContainer>
      )}

      {compToShow === 'interm' && (
        <CardsContainer>
          <CardPlan>
            <CardHeader>
              <CardTitle>Essencial</CardTitle>
              <PriceImg src={plan4}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              20 Campanhas trackeadas
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              200 mil clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              12 meses de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>

          <CardPlan>
            <CardHeader>
              <CardTitle>Expansivo</CardTitle>
              <PriceImg src={plan5}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              30 Campanhas trackeadas
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              320 mil clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              12 meses de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>

          <CardPlan>
            <CardHeader>
              <CardTitle>Escalado</CardTitle>
              <PriceImg src={plan6}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              40 Campanhas trackeadas
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              450 mil clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              12 meses de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>
        </CardsContainer>
      )}
      
      {compToShow === 'advanc' && (
        <CardsContainer>
          <CardPlan>
            <CardHeader>
              <CardTitle>Sênior</CardTitle>
              <PriceImg src={plan7}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Campanhas trackeadas - Ilimitado
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              1 milhão e 500 mil clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              24 meses de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>

          <CardPlan>
            <CardHeader>
              <CardTitle>Mestre</CardTitle>
              <PriceImg src={plan8}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Campanhas trackeadas - Ilimitado
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              2 milhões de clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              24 meses de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>

          <CardPlan>
            <CardHeader>
              <CardTitle>Ancient</CardTitle>
              <PriceImg src={plan9}/>
              <RegisterBtn onClick={() => openWaitListModal()}>Cadastre-se</RegisterBtn>
            </CardHeader>

            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Campanhas trackeadas - Ilimitado
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              3 milhões de clicks por mês
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              24 meses de retenção dos dados
            </CardText>
            <CardText>
              <CheckIcon className='link-icon' style={{ fill: 'rgb(49 186 91)' }}/>
              Onboarding de integração
            </CardText>
          </CardPlan>
        </CardsContainer>
      )}
    </Container>
  )
}

export default PriceSection;