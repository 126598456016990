import { useState } from 'react'
import { ContainerNewCampaign, HeaderNewCampaign } from './styles'
import { User } from '../../../models/user'
import { CampaignModel } from '../../../models/campaing'
import { AccountTaboolaDataModel } from '../../../models/account-taboola-data'
import TaboolaCampaign from './TaboolaCampaign'
import TaboolaLogo from '../../../assets/taboola.png'
import MetaLogo from '../../../assets/meta.png'
import MetaCampaign from './MetaCampaign'
import Button from '../../FormElements/Button'

interface Props {
    user?: User []
    taboolaInfo?: AccountTaboolaDataModel[]
    updateCampaignArr: (campaign: CampaignModel) => void
    createCampaignStatusMessage: (valid: boolean, status?: boolean) => void
}

const ModalNewCampaign = ({ user, taboolaInfo, updateCampaignArr, createCampaignStatusMessage }: Props) => {
    const [selectedAdProvider, setSelectedAdProvider] = useState<string>()

    const resetSelectedAdProvider = () => {
        setSelectedAdProvider(undefined)
    }

    const closeModal = () => {
        const modal = document.getElementById('new-campaign')
        if (modal) {
            modal.style.display = 'none'
        }
    }

    
    return (
        <ContainerNewCampaign>
            <HeaderNewCampaign>Criar Campanha</HeaderNewCampaign>

            {!selectedAdProvider && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px', marginTop: '20px' }}>
                        <img src={TaboolaLogo} alt="Taboola Logo" style={{ width: '100px', height: 'auto', cursor: 'pointer' }} onClick={() => setSelectedAdProvider('taboola')}/>
                        <img src={MetaLogo} alt="Outbrain Logo" style={{ width: '120px', height: 'auto', cursor: 'pointer' }} onClick={() => setSelectedAdProvider('meta')}/>

                    </div>
                    <div style={{padding: '15px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                        <Button type='button' size='medium' model='cancel' width='20%' text='Cancelar' mobileWidth='80%' onClick={() => closeModal()} />
                    </div>
                </>
            )}

            {selectedAdProvider === 'taboola' && (
                <TaboolaCampaign 
                    user={user}
                    taboolaInfo={taboolaInfo}
                    updateCampaignArr={updateCampaignArr}
                    createCampaignStatusMessage={createCampaignStatusMessage}
                    resetSelectedAdProvider={resetSelectedAdProvider}
                />
            )}

            {selectedAdProvider === 'meta' && (
                <MetaCampaign 
                    user={user}
                    taboolaInfo={taboolaInfo}
                    updateCampaignArr={updateCampaignArr}
                    createCampaignStatusMessage={createCampaignStatusMessage}
                    resetSelectedAdProvider={resetSelectedAdProvider}
                />
            )}
        </ContainerNewCampaign>
    )
}

export default ModalNewCampaign