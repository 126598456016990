import { LogoContainer, NavbarContainer, LinksContainer, ListItem, LogoutContainer } from './styles'
import WifiIcon from '@mui/icons-material/Wifi';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { NavLink, useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { User } from '../../models/user';
import { logout } from '../../services';
import { db } from '../../db/db';

interface Props {
  user?: User []
}

const Navbar = ({ user }: Props) => {
  const navigate = useNavigate()

  const logoutUser = () => {
    if(user) {
      logout(user[0].access_token)
      .then(resp => {
        db.taboola.clear()
        db.user.clear()
        navigate('/login')
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  return(
    <NavbarContainer>
      <LogoContainer>Orac<span style={{color: "rgb(119 190 77)"}}>clum</span></LogoContainer>

      <LinksContainer>
        <ListItem>
          <NavLink to='/integrations'>
            <WifiIcon className='link-icon' style={{ fill: '#ffffff' }}/>
            Integrações
          </NavLink>
        </ListItem>

        <ListItem>
          <NavLink to='/campaigns'>
            <TimelineRoundedIcon className='link-icon' style={{ fill: '#ffffff' }}/>
            Campanhas
          </NavLink>
        </ListItem>

        <ListItem>
          <NavLink to='/settings'>
            <ManageAccountsIcon className='link-icon' style={{ fill: '#ffffff'}}/>
            Configurações
          </NavLink>
        </ListItem>
      </LinksContainer>

      <LogoutContainer onClick={() => logoutUser()}>
        <ExitToAppIcon className='logout-icon' style={{ fill: '#fff'}}/>
        LOGOUT
      </LogoutContainer>
      
    </NavbarContainer>
  )
}

export default Navbar