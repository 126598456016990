import styled from 'styled-components'

export const ContainerEditCampaign = styled.div`
    max-height: 85vh;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;

    @media screen and (max-width: 750px) {
        width: 90%;
        max-height: 75vh;
    }
`

export const HeaderNewCampaign = styled.div`
    width: 100%;
    font-size: 3.5vh;
    font-weight: 200;
    height: 10vh;
    background-color: #dfdfdf;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #343434;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ContainerEditCampaignForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-top: 15px;
`

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
`

export const InputLabel = styled.div`
    font-size: 15px;
    width: 45%;
    display: flex;
    justify-content: flex-end;
`

export const InputNumber = styled.input`
    font-size: 12px;
    margin-left: 15px;
    padding: 5px;
    width: 90px;
    border-radius: 3px;
    border: 1px solid #cecece;
    text-align: center;
`

export const MessageSuccess = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 15px;
`
