import styled from "styled-components";

export const NavbarContainer = styled.div`
  background-color: #222222;
  border-top-right-radius: 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (max-width: 750px) {
    display: none;
  }
`

export const LogoContainer = styled.div`
  margin: 30px;
  color: white;
  font-size: 24px;
  font-weight: 800;
  display: flex;
  gap: 1px;
`

export const LinksContainer = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
  width: 100%;
  overflow: auto;
`

export const ListItem = styled.div`
  & > a {
    border: 1px solid transparent;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 15px;
    height: 70px;
    padding-left: 30px;
    text-transform: uppercase;
    padding-right: 30px;
  }

  & > a:hover {
    color: rgb(119 190 77);
  
    .link-icon {
      fill: rgb(119 190 77) !important;
    }
  }
  
  & > a.active {
    background-color: white;
    color: rgb(119 190 77);

    .link-icon {
      fill: rgb(119 190 77) !important;
    }
  }
`

export const LogoutContainer = styled.div`
  display: flex;
  color: white;
  font-size: 15px;
  font-weight: 300;
  height: 70px;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;

  &:hover {
    color: rgb(119 190 77);

    .logout-icon {
      fill: rgb(119 190 77) !important;
    }
  }
`