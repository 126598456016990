import { AccountTaboolaDataModel } from '../../models/account-taboola-data'
import Button from '../FormElements/Button'
import { HeaderContainer, HeaderTitle } from './styles'


interface Props {
    title: string
    button1?: string
    taboolaInfo?: AccountTaboolaDataModel[]
    button1Func?: () => void
}

const HeaderPage = ({title, button1, taboolaInfo, button1Func}: Props) => {
    return(
        <HeaderContainer>
            <HeaderTitle>{title}</HeaderTitle>

            <div>
                {button1 && <Button type='button' size='large' model='add' width='100%'  text={button1} mobileWidth='100%' onClick={button1Func} />}
            </div>
           
        </HeaderContainer>
    )
}

export default HeaderPage