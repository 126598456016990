import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import NavbarMobile from '../../components/Navbar/NavbarMobile';
import { Container, Content } from './styles';
import { AccountTaboolaDataModel } from '../../models/account-taboola-data';

interface Props {
  user?: any
  taboolaInfo?: AccountTaboolaDataModel[]
}

const Layout = ({ user, taboolaInfo }: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const openPaths = ['/landing', '/login', '/use-terms', '/policies']
    if (user && user.length > 0) {
      if (user[0].access_token && (window.location.pathname === '/login' || window.location.pathname === '/landing')) {
        navigate('/campaigns');
      }
    } else if (!openPaths.includes(window.location.pathname)) {
      navigate('/landing')
    }
  }, [user, navigate]);
  
  return (
    <Container>
      {user && user.length > 0 &&
         <>
          <Navbar user={user} />
          <NavbarMobile />
        </>
      }
      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};

export default Layout;