import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #dfdfdf;
`

export const LogoImg = styled.img`
    width: 50%;

    @media screen and (max-width: 750px) {
        width: 100%;
    }
`

export const FormContainer = styled.form`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
`