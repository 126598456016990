import { useNavigate } from 'react-router-dom';
import { CampaignModel } from '../../../models/campaing'
import { HeaderContainer, GoBackContainer, HeaderTitle, ContainerSummary, SummaryData, SummaryColumn, ContainerTaboolaCampaignInfo, TaboolaData, TaboolaTitle, TimePickerContainer } from './styles'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TimePicker from '../../../components/TimePicker';
import { CampaignTaboolaData } from '../../../models/campaign-taboola-data';
import EditIcon from '@mui/icons-material/Edit';
import Loading from '../../../components/Loading/Small';


interface Props {
    campaign?: CampaignModel
    pickDate: number
    campaignTaboolaData?: CampaignTaboolaData
    selectClick: (days: number) => void
    openEditModal: () => void
}

const HeaderPage = ({campaign, pickDate, campaignTaboolaData, selectClick, openEditModal}: Props) => {
    const navigate = useNavigate()

    const redirectPage = () => {
        navigate('/campaigns/')
    }

    return(
        <HeaderContainer>
            <div>
                <GoBackContainer onClick={() => redirectPage()}>
                    <ExpandLessIcon style={{ 
                        fill: 'rgb(119 190 77)',
                        width: '25px', 
                        height: '25px', 
                        rotate: '270deg',
                        top: '50px'
                    }} />
                    voltar
                </GoBackContainer>
                <HeaderTitle>{campaign?.name}</HeaderTitle>
            </div>

            <ContainerSummary>
                <SummaryColumn>
                    <SummaryData>Gasto: <strong>$ {campaign?.summary?.expenses.toFixed(2).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>Fatur.: <strong>$ {campaign?.summary?.revenue.toFixed(2).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>Clicks: <strong>{campaign?.summary?.clicks}</strong></SummaryData>
                    <SummaryData>Checkout: <strong>{campaign?.summary?.checkout}</strong></SummaryData>
                    <SummaryData>Conversões: <strong>{campaign?.summary?.sales}</strong></SummaryData>
                </SummaryColumn>

                <SummaryColumn>
                    <SummaryData>ROAS: <strong>{campaign?.summary && (campaign.summary.roas*100).toFixed(2).replace('.', ',')}%</strong></SummaryData>
                    <SummaryData>cpc atual: <strong>{campaign?.summary?.cpc.toFixed(3).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>vcpm: <strong>{campaign?.summary?.vcpm.toFixed(2).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>cpa: <strong>{campaign?.summary?.cpa.toFixed(2).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>vctr: <strong>{campaign?.summary?.vctr.toFixed(2).replace('.', ',')}%</strong></SummaryData>
                </SummaryColumn>
            </ContainerSummary>

            <ContainerTaboolaCampaignInfo onClick={() => openEditModal()}>
                {campaignTaboolaData? (
                    <>
                        <TaboolaTitle>
                            Taboola
                            <EditIcon className="edit-data" style={{ fill: '#000', width: '15px', height: '15px' }}/>    
                        </TaboolaTitle>

                        <TaboolaData>CPC: <strong>{campaignTaboolaData? String(campaignTaboolaData.cpc).replace('.', ',') : '0,00'}</strong></TaboolaData>
                        <TaboolaData>Limite diário: <strong>${campaignTaboolaData? String(campaignTaboolaData.daily_cap).replace('.', ',') : '0,00'}</strong></TaboolaData>
                        <TaboolaData>Status: <strong>{campaignTaboolaData? (campaignTaboolaData.active? 'Ativa' : 'Pausada') : 'Pausada' }</strong></TaboolaData>
                    </>
                ) : (
                    <Loading />
                )}
                
            </ContainerTaboolaCampaignInfo>
            
            <TimePickerContainer>
                <TimePicker selected={pickDate} selectClick={selectClick} />            
            </TimePickerContainer>
        </HeaderContainer>
    )
}

export default HeaderPage