import axios, { AxiosRequestConfig } from "axios";

export const config: AxiosRequestConfig = {
	baseURL: 'https://backstage.taboola.com/backstage/',
	responseType: 'json',
	headers: {
		accept: 'application/json',
		'content-type': 'application/json',
//		authorization: 'Bearer CS04AAAAAAAAEXpoQwEAAAAAGAEgACkpXIn4jwEAADooYWZiMWQ5YWMyYjU1NmFjZTFlNTcwMjU0ZmVhOGI2ZjUzOGRlN2M3NEAC::c313ca::391e1f'
	},
	timeout: 40000,
	withCredentials: false,
	maxRedirects: 5,
	validateStatus: (status: number) => status >= 200 && status < 300,
	transformResponse: (response) => {
		if (response !== null && response.error) {
			console.log(response.error);
		}
		let resp;
		try {
			resp = JSON.parse(response);
		} catch {
			resp = response;
		}
		return resp;
	},
};

export const getSubAccounts = async (account_id: string, access_token: string) => {
	config.url = `https://backstage.taboola.com/backstage/api/1.0/${account_id}/advertisers`
	config.method = 'get'
	if (config.headers) config.headers.authorization = `Bearer ${access_token}`

	return axios.request<any>(config);
};

export const getTaboolaCampaignData = async (account_id: string, campaign_id: string, access_token: string, controller: AbortController) => {
	config.url = `api/1.0/${account_id}/campaigns/${campaign_id}`
	config.method = 'get'
	if (config.headers) config.headers.authorization = `Bearer ${access_token}`
	config.signal = controller.signal

	return axios.request<any>(config);
};

export const updateTaboolaCampaign = async (access_token: string, account_id: string, campaign_id: string, data: any) => {
	config.url = `api/1.0/${account_id}/campaigns/${campaign_id}`
	config.method = 'post'
	if (config.headers) config.headers.authorization = `Bearer ${access_token}`
	config.signal = undefined
	config.data = data

	return axios.request<any>(config);
};

export const getTaboolaAdsData = async (account_id: string, campaign_id: string, access_token: string, controller: AbortController) => {
	config.url = `api/1.0/${account_id}/campaigns/${campaign_id}/items`
	config.method = 'get'
	if (config.headers) config.headers.authorization = `Bearer ${access_token}`
	config.signal = controller.signal

	return axios.request<any>(config);
};

export const updateTaboolaAd = async (access_token: string, account_id: string, campaign_id: string, item_id: number, data: any) => {
	config.url = `api/1.0/${account_id}/campaigns/${campaign_id}/items/${item_id}/`
	config.method = 'post'
	if (config.headers) config.headers.authorization = `Bearer ${access_token}`
	config.signal = undefined
	config.data = data

	return axios.request<any>(config);
};

export const loginTaboola = async (id: string, secret: string) => {
	const encodedParams = new URLSearchParams();
	encodedParams.set('client_id', id);
	encodedParams.set('client_secret', secret);
	encodedParams.set('grant_type', 'client_credentials');
	
	config.url = 'oauth/token'
	config.method = 'post'
	config.headers = {'content-type': 'application/x-www-form-urlencoded'}
	config.data = encodedParams

	return axios.request<any>(config);
}

