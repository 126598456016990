import styled from "styled-components";

export const SettingsPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

`

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  background-color: #dfdfdf;
  gap: 20px;
  overflow: auto;
  box-shadow: 0px 0px 5px 1px black;

  @media screen and (max-width: 750px) {
    padding: 10px;
    padding-bottom: 50px;
  }
`

export const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding-bottom: 30px;
`

export const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
`

export const ChangePass = styled.div`
  display: flex;
  flex-direction: column;
`

export const PassTitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  padding: 30px 0px 15px 0px;
`

export const UserInfo = styled.div`
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 300;
  width: 40%;
`

export const LogoutBtn = styled.button`
  border-radius: 50%;
  border: none;
  width: 40px;
  height: 40px;
  background: #343434;
  padding: 5px;
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;

  @media screen and (max-width: 750px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
