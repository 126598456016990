import { CampaignStepsSummary } from '../../../models/campaign-steps-summary'
import PizzaGraph from '../../UiElements/PizzaGraph'
import { FunnelLine, FunnelTitle, FunnelLink, DataContainer, StepsContainer, InfoContainer, DataValues } from './styles'

interface Props {
    clicks_taboola: number
    stepsSummary: CampaignStepsSummary
    expenses: number
}

const FunnelSummaryLine = ({ stepsSummary, expenses, clicks_taboola }: Props) => {
    const dataClicksGraph = [
        { value: clicks_taboola, color: 'rgb(119 190 77)', label: 'Clicks' },
    ];

    const dataStep1 = [
        { value: stepsSummary.total_step_1, color: 'rgb(119 190 77)', label: 'Start passo 2' },
        { value: clicks_taboola - stepsSummary.total_step_1, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataStep2 = [
        { value: stepsSummary.total_step_2, color: 'rgb(119 190 77)', label: 'Start passo 3' },
        { value: stepsSummary.total_step_1 - stepsSummary.total_step_2, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataStep3 = [
        { value: stepsSummary.total_step_3, color: 'rgb(119 190 77)', label: 'Start passo 3' },
        { value: stepsSummary.total_step_2 - stepsSummary.total_step_3, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataCheckout = [
        { value: stepsSummary.total_checkout, color: 'rgb(119 190 77)', label: 'Checkout' },
        { value: stepsSummary.total_step_3? stepsSummary.total_step_3 - stepsSummary.total_checkout : stepsSummary.total_step_2? stepsSummary.total_step_2 - stepsSummary.total_checkout : stepsSummary.total_step_1? stepsSummary.total_step_1 - stepsSummary.total_checkout : clicks_taboola - stepsSummary.total_checkout, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataSales = [
        { value: stepsSummary.total_sales, color: 'rgb(119 190 77)', label: 'Conversões' },
        { value: stepsSummary.total_checkout - stepsSummary.total_sales, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];
    const size = {
        width: 80,
        height: 80
    }

    return(
        <FunnelLine>
            <DataContainer>
                <div>
                    <FunnelTitle>{stepsSummary.name}</FunnelTitle>
                    <FunnelLink>{stepsSummary.link}</FunnelLink>
                </div>

                <InfoContainer>
                    <DataValues>Gasto: <strong>$ {expenses?.toFixed(2).replace('.', ',')}</strong></DataValues>
                    <DataValues>Fatur.: <strong>$ {stepsSummary.revenue?.toFixed(2).replace('.', ',')}</strong></DataValues>
                    <DataValues>ROAS: <strong>{expenses>0? ((stepsSummary.revenue/expenses)*100).toFixed(2).replace('.', ',') : 0.00}%</strong></DataValues>
                </InfoContainer>
            </DataContainer>
           
            <StepsContainer>
                <PizzaGraph labelChart={'Clicks'} data={dataClicksGraph} size={size} labelDonut={clicks_taboola}/>
                {stepsSummary.total_step_1 > 0 && (
                    <PizzaGraph labelChart={'Passo 2'} data={dataStep1} size={size} labelDonut={stepsSummary.total_step_1}/>
                )}
                {stepsSummary.total_step_2 > 0 && (
                    <PizzaGraph labelChart={'Passo 3'} data={dataStep2} size={size} labelDonut={stepsSummary.total_step_2}/>
                )}
                {stepsSummary.total_step_3 > 0 && (
                    <PizzaGraph labelChart={'Passo 4'} data={dataStep3} size={size} labelDonut={stepsSummary.total_step_3}/>
                )}
                <PizzaGraph labelChart={'Checkout'} data={dataCheckout} size={size} labelDonut={stepsSummary.total_checkout}/>
                <PizzaGraph labelChart={'Conversões'} data={dataSales} size={size} labelDonut={stepsSummary.total_sales}/>
            </StepsContainer>
        </FunnelLine>
    )
}

export default FunnelSummaryLine