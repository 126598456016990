import { useNavigate } from "react-router-dom"
import { AdsSummaryModel } from "../../../models/ads-summary"
import { AdsLine, AdThumbnail, DataContainer, AdTitleLine, AdTitle, OptimizationContainer, OptimizationInfo, TitleText } from "./styles"
import { CampaignSummaryModel } from "../../../models/campaing-summary"
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { useState } from "react";
import { updateTaboolaAd } from "../../../services/taboola";
import { AccountTaboolaDataModel } from "../../../models/account-taboola-data";
import Loading from "../../Loading/Small";

interface Props {
    ad: AdsSummaryModel
    campaign: CampaignSummaryModel
    taboolaInfo?: AccountTaboolaDataModel[]
    pickDate: number
    editAdStatusMessage: (status: boolean) => void
}

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));
const label = { inputProps: { 'aria-label': 'controlled' } };

const AdsSummaryLine = ({ ad, campaign, taboolaInfo, pickDate, editAdStatusMessage }: Props) => {
    const navigate = useNavigate()
    const [isActive, setIsActive] = useState<boolean>(ad.status)
    const [loading, setLoading] = useState<boolean>(false)

    const redirectPage = () => {
        navigate('/campaigns/' + campaign.id + '/ads/' + ad.id_ads_taboola + '/summary', {state: { campaignSummary: campaign, ad: ad, pickDate: pickDate }} )
    }
    
    const updateAd = (e: any) => {
        setLoading(true)
        if(taboolaInfo && campaign.id_campaign_taboola){
            const data = {
                is_active: e.target.checked
            }
            updateTaboolaAd(taboolaInfo[0].access_token, taboolaInfo[0].account_id, campaign.id_campaign_taboola, ad.id_ads_taboola, data)
            .then(resp => {
                setIsActive(resp.data.is_active)
                setLoading(false)
                editAdStatusMessage(true)
            })
            .catch(err => {
                editAdStatusMessage(false)
                console.log(err)
            })
        }
    }

    return (
        <AdsLine>
            <AdThumbnail src={ad.thumbnail} />
            <DataContainer>
                <AdTitleLine>
                    {loading? (
                        <Loading />
                    ) : (
                        <GreenSwitch {...label} checked={isActive} onChange={(e) => updateAd(e)} />
                    )}
                    <AdTitle onClick={() => redirectPage()}>
                        <TitleText>{ad.title}</TitleText>
                        <span style={{fontSize: '12px', minWidth: '90px'}}>id: {ad.id_ads_taboola}</span> 
                    </AdTitle>
                </AdTitleLine>
                
                <OptimizationContainer>
                    <OptimizationInfo>Fatur. <strong>{ad.summary?.revenue.toFixed(2).replace('.', ',')}</strong></OptimizationInfo>
                    <OptimizationInfo>Gasto <strong>{ad.summary?.expenses? ad.summary.expenses.toFixed(2).replace('.', ',') : '0,00'}</strong></OptimizationInfo>
                    <OptimizationInfo>Clicks <strong>{ad.summary?.clicks}</strong></OptimizationInfo>
                    <OptimizationInfo>Checkout <strong>{ad.summary?.checkout}</strong></OptimizationInfo>
                    <OptimizationInfo>Conversões <strong>{ad.summary?.sales}</strong></OptimizationInfo>
                    <OptimizationInfo>cpc <strong>{ad.summary?.cpc? ad.summary.cpc.toFixed(3).replace('.', ','): '0,00'}</strong></OptimizationInfo>
                    <OptimizationInfo>vcpm <strong>{ad.summary?.vcpm.toFixed(2).replace('.', ',')}</strong></OptimizationInfo>
                    <OptimizationInfo>cpa <strong>{ad.summary?.cpa? ad.summary.cpa.toFixed(2).replace('.', ',') : '0,00' }</strong></OptimizationInfo>
                    <OptimizationInfo>vctr <strong>{ad.summary?.vctr.toFixed(2).replace('.', ',')}%</strong></OptimizationInfo>
                    <OptimizationInfo>ROAS <strong>{ad.summary && (ad.summary.roas*100).toFixed(2).replace('.', ',')}%</strong></OptimizationInfo>
                </OptimizationContainer>
            </DataContainer>
        </AdsLine>
    )
}

export default AdsSummaryLine


