import styled from "styled-components";

export const CampaingLayoutPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`

