import { NavbarContainer, LinksContainer, ListItem } from './styles'
import WifiIcon from '@mui/icons-material/Wifi';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { NavLink } from 'react-router-dom';

const NavbarMobile = () => {
  return(
    <NavbarContainer>
      <LinksContainer>
        <ListItem>
          <NavLink to='/integrations' className='link-icon' style={{flexDirection: 'column'}}>
            <WifiIcon className='link-icon' style={{ fill: '#ffffff' }}/>
            Integraç.
          </NavLink>
        </ListItem>

        <ListItem>
          <NavLink to='/campaigns' style={{flexDirection: 'column'}}>
            <TimelineRoundedIcon className='link-icon' style={{ fill: '#ffffff' }}/>
            Campanhas
          </NavLink>
        </ListItem>

        <ListItem>
          <NavLink to='/settings' style={{flexDirection: 'column'}}>
            <ManageAccountsIcon className='link-icon' style={{ fill: '#ffffff' }}/>
            Config.
          </NavLink>
        </ListItem>
      </LinksContainer>        
    </NavbarContainer>
  )
}

export default NavbarMobile