import Button from '../../FormElements/Button'
import { ContainerNewCampaign, HeaderNewCampaign, ContainerNewCampaignForm, MessageSuccess } from './styles'

interface Props {
    closeModalAllowClicks: () => void
}
const ModalAllowClicks = ({ closeModalAllowClicks }: Props) => {

    return (
        <ContainerNewCampaign>
            <HeaderNewCampaign>Aviso</HeaderNewCampaign>

            <ContainerNewCampaignForm>
                <MessageSuccess><strong>Você atingiu o número máximo de clicks para o seu plano.</strong></MessageSuccess>
                <small>Para continuar registrando novos clicks entre em contato com a nosssa equipe para a alteração de plano ou aguarde o fechamento do mês.</small>
                
                <div style={{paddingTop: '10px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                    <Button type='button' size='medium' model='confirm' width='30%' text='Fechar' mobileWidth='80%' onClick={() => closeModalAllowClicks()} />
                </div>
            </ContainerNewCampaignForm>
        </ContainerNewCampaign>
    )
}

export default ModalAllowClicks