import { useState } from "react"
import { CompareContainer, ComparePeriod, DateContainer, Period, SelectComparePeriod, SelectPeriod } from "./styles"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

interface Props {
    selected?: number
    selectClick: (selectedPeriod: number) => void
    compareOptions?: boolean
    selectToCompare?: (period: string, value:boolean) => void
    today?: boolean
    yesterday?: boolean
    last3?: boolean
    last7?: boolean
    last15?: boolean
    last30?: boolean
    last60?: boolean
    last90?: boolean
    allTime?: boolean
}
const TimePicker = ({ selected, selectClick, compareOptions, selectToCompare, today, yesterday, last3, last7, last15, last30, last60, last90, allTime }: Props) => {
    const [clicked, setClicked] = useState<boolean>(false)

    return (
        !clicked? (
            <DateContainer onClick={() => setClicked(true)}>
                <CalendarMonthIcon className="link-data" style={{ fill: '#ffffff', width: '25px', height: '25px' }} />
            </DateContainer>
        ) : (
            !compareOptions? (
                <SelectPeriod  onClick={() => setClicked(false)}>
                    <Period className={selected === 0? 'active' : ''} onClick={() => selectClick(0)}>Hoje</Period>
                    <Period className={selected === 1? 'active' : ''} onClick={() => selectClick(1)}>Ontem</Period>
                    <Period className={selected === 3? 'active' : ''} onClick={() => selectClick(3)}>3 Dias</Period>
                    <Period className={selected === 7? 'active' : ''} onClick={() => selectClick(7)}>7 Dias</Period>
                    <Period className={selected === 15? 'active' : ''} onClick={() => selectClick(15)}>15 Dias</Period>
                    <Period className={selected === 30? 'active' : ''} onClick={() => selectClick(30)}>30 Dias</Period>
                    <Period className={selected === 60? 'active' : ''} onClick={() => selectClick(60)}>60 Dias</Period>
                    <Period className={selected === 90? 'active' : ''} onClick={() => selectClick(90)}>90 Dias</Period>
                    <Period className={selected === 500? 'active' : ''} onClick={() => selectClick(500)}>Vitalício</Period>
                </SelectPeriod>
            ) : (

                <CompareContainer>
                    <div style={{
                        background: '#343434',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        lineHeight: '20px',
                        width: '25px',
                        borderRadius: '50%',
                        height: '25px',
                        color: 'rgb(119 190 77)',
                        cursor: 'pointer',
                        border: '1px solid #343434'
                    }}  onClick={() => setClicked(false)}>x</div>

                    <SelectComparePeriod>
                        <ComparePeriod className={today? 'active' : ''} onClick={() => selectToCompare && selectToCompare('today', !today)}>Hoje</ComparePeriod>
                        <ComparePeriod className={yesterday? 'active' : ''} onClick={() => selectToCompare && selectToCompare('yesterday', !yesterday)}>Ontem</ComparePeriod>
                        <ComparePeriod className={last3? 'active' : ''} onClick={() => selectToCompare && selectToCompare('last3', !last3)}>3 Dias</ComparePeriod>
                        <ComparePeriod className={last7? 'active' : ''} onClick={() => selectToCompare && selectToCompare('last7', !last7)}>7 Dias</ComparePeriod>
                        <ComparePeriod className={last15? 'active' : ''} onClick={() => selectToCompare && selectToCompare('last15', !last15)}>15 Dias</ComparePeriod>
                        <ComparePeriod className={last30? 'active' : ''} onClick={() => selectToCompare && selectToCompare('last30', !last30)}>30 Dias</ComparePeriod>
                        <ComparePeriod className={last60? 'active' : ''} onClick={() => selectToCompare && selectToCompare('last60', !last60)}>60 Dias</ComparePeriod>
                        <ComparePeriod className={last90? 'active' : ''} onClick={() => selectToCompare && selectToCompare('last90', !last90)}>90 Dias</ComparePeriod>
                        <ComparePeriod className={allTime? 'active' : ''} onClick={() => selectToCompare && selectToCompare('allTime', !allTime)}>Vitalício</ComparePeriod>
                    </SelectComparePeriod>
                </CompareContainer>
            )
        )
    )
}

export default TimePicker
