import styled from 'styled-components'

export const CardContainer = styled.div`
  width: 30%;
  max-width: 350px;
  text-decoration: none;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  
  &:hover {
    top: -5px;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 10px 15px;
  color: #343434;
  cursor: pointer;
`

export const Title = styled.div`
  font-size: 32px;
  font-weight: 200;
  max-height: 40px;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    max-height: unset;
    text-overflow: unset;
    overflow: unset;
    white-space: normal;
  }

  @media screen and (max-width: 750px) {
    max-height: unset;
    text-overflow: unset;
    overflow: unset;
    white-space: normal;
}
` 

export const CampaingId = styled.div`
  font-size: 12px;
  color: #757575;
  padding-bottom: 3px;
  border-bottom: 1px solid #dfdfdf;
` 

export const TabsContainer = styled.div`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`
export const Tab = styled.div`
  font-size: 13px;
  color: #343434;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 400;

  &.active {
    font-size: 15px;
    font-weight: 700;
  }
`


export const SummaryContainer = styled.div`
  display: flex;
  padding: 0px 15px;

`

export const SummaryInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 15px;
  align-items: end;
`

export const SummaryData = styled.div`
  font-size: 12px;
  display: flex;
  padding-bottom: 3px;
  justify-content: space-between;
  width: 80%;
`

export const GraphContainer = styled.div`
`

export const FunnelStepsContainer = styled.div`
  padding: 0px 15px 15px 15px;
  display: flex;
  justify-content: center;
`

export const GraphStepContainer = styled.div`
  width: 33%;
  border-top: 1px solid #dfdfdf;
  padding-top: 10px;
`


