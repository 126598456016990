import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
`

export const Header = styled.div`
  background: black;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 10;
`

export const Logo = styled.div`
  color: white;
  font-size: 25px;
  cursor: pointer;
  padding: 20px;
  display: flex;
  gap: 1px;
  font-weight: 600;
`

export const GetToKnow = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 50px;
  background-color: rgb(34, 34, 34);
  color: white;
  align-items: left;
  padding: 40px 15px;

  @media screen and (max-width: 750px) {
    font-size: 35px;
  }
`

export const Title = styled.div`
  font-size: 40px;
  align-self: center;
  padding: 30px;
  @media screen and (max-width: 750px) {
    text-align: center;
    font-size: 22px;
  }
`

export const TitleAdv = styled.div`
  font-size: 26px;
  padding-bottom: 10px;
  @media screen and (max-width: 750px) {
    text-align: center;
    font-size: 22px;
  }
`

export const Text = styled.div`
  font-size: 18px;
  padding-bottom: 30px;

  @media screen and (max-width: 750px) {
    text-align: center;
    padding-top: 5px;
    font-size: 14px;
  }
`