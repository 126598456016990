import styled from "styled-components"

export const AdsLine = styled.div`
    display: flex;
    background: white;
    border-radius: 5px;
    
    @media screen and (max-width: 750px) {
        flex-direction: column;
        align-items: center;
        padding: 5px;
    }
`

export const AdThumbnail = styled.img`
    width: 90px;
    height: 90px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @media screen and (max-width: 750px) {
        width: 50%;
        height: 50%;
        margin-top: 5px;
        border-radius: 5px;
    }
`

export const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
`

export const AdTitleLine = styled.div`
    display: flex;

    @media screen and (max-width: 750px) {
        flex-direction: column;
    }
`

export const TitleText = styled.div`
    max-width: 650px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

     @media screen and (max-width: 750px) {
        max-width: 250px;
        max-height: 60px;
        white-space: wrap;
    }
`

export const AdTitle = styled.div`
    font-size: 25px;
    font-weight: 200;
    display: flex;
    padding: 0px 10px;
    justify-content: space-between;
    cursor: pointer;

    @media screen and (max-width: 750px) {
        align-items: start;
    }
`

export const OptimizationContainer = styled.div`
    display: flex;
    padding-top 5px;
    justify-content: space-between;

    @media screen and (max-width: 750px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
    }
`

export const OptimizationInfo = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-start;
    align-items: center;
    min-width: 85px;
    padding-top: 5px;
`