import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    display: none;
  }
`

export const HeaderTitle = styled.div`
  font-size: 4vh;
  color: #343434;
  font-weight: 200;
`

export const GoBackContainer = styled.div`
  display: flex;
  color: rgb(119 190 77);
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;
`

export const ContainerSummary = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

export const SummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;

  @media screen and (max-width: 750px) {
    padding-top: 5px;
    width: 48%;
  }
`

export const SummaryData = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
`

export const TabsContainer = styled.div`
  border-top: 1px solid rgb(119 190 77);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
export const Tab = styled.div`
  font-size: 11px;
  color: #343434;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 400;

  &.active {
    font-size: 12px;
    font-weight: 600;
    color: rgb(119 190 77);
  }
`