import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  flex-grow: 1;
  background-color: #dfdfdf;
  overflow: auto;
  padding: 10px;
  box-shadow: 0px 0px 5px 1px black;

  @media screen and (max-width: 750px) {
    padding: 10px;
    padding-bottom: 60px;
    flex-wrap: nowrap;
  }
`

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`
export const Tab = styled.div`
  font-size: 13px;
  color: #343434;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 400;
  border-radius: 5px;
  padding: 8px;
  width: 80px;

  &.active {
    font-size: 14px;
    font-weight: 600;
    color: rgb(119 190 77);
    border: 1px solid rgb(119 190 77);
  }
`

export const AdsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  overflow: auto;
  padding-top: 15px;
`
