import { SiteRowContainer, OptimizationContainer, OptimizationInfo, 
    IconContainer, SiteDomain, DataRow, SelectedPeriod, CompareDataContainer, 
    Period, ExtraOptimizationInfo } from "./styles"
import { OptimizationData } from "../../models/optimization-data"
import { CampaignSiteSummaryModel } from "../../models/campaign-sites-summary"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useState } from "react";
import { User } from "../../models/user";
import { loadOneSiteOptimizationData } from "../../services";
import { CampaignTaboolaData } from "../../models/campaign-taboola-data";
import { AccountTaboolaDataModel } from "../../models/account-taboola-data";
import Loading from "../Loading/Small";
import EditTaboola from "./EditBid";

interface Props {
    user?: User []
    site: CampaignSiteSummaryModel
    pickDate: number
    today: boolean
    yesterday: boolean
    last3: boolean
    last7: boolean
    last15: boolean
    last30: boolean
    last60: boolean
    last90: boolean
    allTime: boolean
    campaignTaboolaData: CampaignTaboolaData
    taboolaInfo?: AccountTaboolaDataModel[]
    id_campaign_taboola: string
    updateCampaignTaboolaData: (data: any) => Promise<boolean>
    editSiteStatusMessage: (status: boolean) => void
}

const SiteRow = ({ site, pickDate, today, yesterday,
    last3, last7, last15, last30, last60, last90, 
    allTime, user, campaignTaboolaData, taboolaInfo,
    id_campaign_taboola, updateCampaignTaboolaData, 
    editSiteStatusMessage }: Props) => {
    const [showData, setShowData] = useState<boolean>(false)
    const [todayData, setTodayData] = useState<OptimizationData>()
    const [yesterdayData, setYesterdayData] = useState<OptimizationData>()
    const [last3Data, setLast3Data] = useState<OptimizationData>()
    const [last7Data, setLast7Data] = useState<OptimizationData>()
    const [last15Data, setLast15Data] = useState<OptimizationData>()
    const [last30Data, setLast30Data] = useState<OptimizationData>()
    const [last60Data, setLast60Data] = useState<OptimizationData>()
    const [last90Data, setLast90Data] = useState<OptimizationData>()
    const [allTimeData, setAllTimeData] = useState<OptimizationData>()

    useEffect(() => {
        const controller = new AbortController();
        if (showData && user) {
            if (today && pickDate !== 0) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 0, controller)
                .then(resp => {
                    setTodayData(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            if (yesterday && pickDate !== 1) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 1, controller)
                .then(resp => {
                    setYesterdayData(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            if (last3 && pickDate !== 3) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 3, controller)
                .then(resp => {
                    setLast3Data(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            if (last7 && pickDate !== 7) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 7, controller)
                .then(resp => {
                    setLast7Data(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            if (last15 && pickDate !== 15) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 15, controller)
                .then(resp => {
                    setLast15Data(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            if (last30 && pickDate !== 30) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 30, controller)
                .then(resp => {
                    setLast30Data(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            if (last60 && pickDate !== 60) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 60, controller)
                .then(resp => {
                    setLast60Data(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            if (last90 && pickDate !== 90) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 90, controller)
                .then(resp => {
                    setLast90Data(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
            if (allTime && pickDate !== 500) {
                loadOneSiteOptimizationData(user[0].access_token, site.id_campaign, Number(site.id_site), 500, controller)
                .then(resp => {
                    setAllTimeData(resp.data)
                })
                .catch(err => {
                    console.log(err)
                })
            }
        } else {
            setTodayData(undefined)
            setYesterdayData(undefined)
            setLast3Data(undefined)
            setLast7Data(undefined)
            setLast15Data(undefined)
            setLast30Data(undefined)
            setLast60Data(undefined)
            setLast90Data(undefined)
            setAllTimeData(undefined)
        }
        
    }, [user, showData, today, yesterday, last3, last7, last15, last30, last60, last90, allTime, pickDate, site])

    return (
        <SiteRowContainer>
            <IconContainer onClick={() => setShowData(!showData)}>
                {showData? (
                    <ExpandLessIcon style={{ fill: 'rgb(119 190 77)', width: '30px', height: '30px' }}/>
                ) : (
                    <ExpandMoreIcon style={{ fill: 'rgb(119 190 77)', width: '30px', height: '30px' }}/>
                )}
            </IconContainer>

            <OptimizationContainer>
                <SiteDomain> 
                    {site.site}
                    <strong style={{fontSize: '12px', minWidth: '75px', height: '15px'}}>id: {site.id_site}</strong> 
                </SiteDomain>

                <DataRow>
                    <SelectedPeriod>{pickDate === 0? 'Hoje' : pickDate === 1? 'Ontem' : pickDate === 500? 'Vitalício' : pickDate+ ' Dias'}</SelectedPeriod>
                    <OptimizationInfo>Faturamento <strong>{site.summary?.revenue.toFixed(2)}</strong></OptimizationInfo>
                    <OptimizationInfo>Gasto <strong>{site.summary?.expenses.toFixed(2)}</strong></OptimizationInfo>
                    <OptimizationInfo>Clicks <strong>{site.summary?.clicks}</strong></OptimizationInfo>
                    <OptimizationInfo>Checkout <strong>{site.summary?.checkout}</strong></OptimizationInfo>
                    <OptimizationInfo>Conversões <strong>{site.summary?.sales}</strong></OptimizationInfo>
                    <OptimizationInfo>cpc <strong>{site.summary?.cpc.toFixed(3)}</strong></OptimizationInfo>
                    <OptimizationInfo>vcpm <strong>{site.summary?.vcpm.toFixed(2)}</strong></OptimizationInfo>
                    <OptimizationInfo>cpa <strong>{site.summary?.cpa.toFixed(2)}</strong></OptimizationInfo>
                    <OptimizationInfo>vctr <strong>{site.summary?.vctr.toFixed(2)}%</strong></OptimizationInfo>
                    <OptimizationInfo>ROAS <strong>{site.summary? (site.summary.roas*100).toFixed(2): 0.00}%</strong></OptimizationInfo>
                </DataRow>

                {showData && today && pickDate !== 0 && (
                    todayData ? (
                        <CompareDataContainer>
                            <Period>Hoje</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{todayData.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{todayData.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{todayData.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{todayData.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{todayData.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{todayData.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{todayData.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{todayData.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{todayData.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(todayData.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>
                    ) : (
                        <Loading />
                    )
                )}

                {showData && yesterday && pickDate !== 1 && (
                    yesterdayData ? (
                        <CompareDataContainer>
                            <Period>Ontem</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{yesterdayData.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{yesterdayData.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{yesterdayData.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{yesterdayData?.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{yesterdayData.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{yesterdayData.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{yesterdayData.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{yesterdayData.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{yesterdayData.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(yesterdayData.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>
                    ) : (
                        <Loading />
                    )
                )}

                {showData && last3 && pickDate !== 3 && (
                    last3Data ? (
                        <CompareDataContainer>
                            <Period>3 Dias</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{last3Data.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{last3Data.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{last3Data.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{last3Data.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{last3Data.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{last3Data.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{last3Data.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{last3Data.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{last3Data.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(last3Data.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>
                    ) : (
                        <Loading />
                    )
                )}

                {showData && last7 && pickDate !== 7 && (
                    last7Data ? (
                        <CompareDataContainer>
                            <Period>7 Dias</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{last7Data.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{last7Data.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{last7Data.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{last7Data.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{last7Data.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{last7Data.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{last7Data.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{last7Data.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{last7Data.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(last7Data.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>
                    ) : (
                        <Loading />
                    )
                )}

                {showData && last15 && pickDate !== 15 && (
                    last15Data ? (
                        <CompareDataContainer>
                            <Period>15 Dias</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{last15Data.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{last15Data.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{last15Data.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{last15Data.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{last15Data.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{last15Data.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{last15Data.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{last15Data.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{last15Data.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(last15Data.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>
                    ) : (
                        <Loading />
                    )
                )}
                {showData && last30 && pickDate !== 30 && (
                    last30Data ? (
                        <CompareDataContainer>
                            <Period>30 Dias</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{last30Data.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{last30Data.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{last30Data.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{last30Data.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{last30Data.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{last30Data.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{last30Data.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{last30Data.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{last30Data.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(last30Data.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>
                    ) : (
                        <Loading />
                    )
                )}

                {showData && last60 && pickDate !== 60 && (
                    last60Data? (
                        <CompareDataContainer>
                            <Period>60 Dias</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{last60Data.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{last60Data.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{last60Data.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{last60Data.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{last60Data.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{last60Data.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{last60Data.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{last60Data.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{last60Data.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(last60Data.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>
                    ) : (
                        <Loading />
                    )
                )}

                {showData && last90 && pickDate !== 90 && (
                    last90Data ? (
                        <CompareDataContainer>
                            <Period>90 Dias</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{last90Data.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{last90Data.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{last90Data.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{last90Data.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{last90Data.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{last90Data.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{last90Data.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{last90Data.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{last90Data.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(last90Data.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>
                    ) : (
                        <Loading />
                    )
                )}

                {showData && allTime && pickDate !== 500 && (
                    allTimeData ? (
                        <CompareDataContainer>
                            <Period>Vitalício</Period>
                            <ExtraOptimizationInfo>Faturamento <strong>{allTimeData.revenue.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Gasto <strong>{allTimeData.expenses.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Clicks <strong>{allTimeData.clicks}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Checkout <strong>{allTimeData.checkout}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>Conversões <strong>{allTimeData.sales}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpc <strong>{allTimeData.cpc.toFixed(3)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vcpm <strong>{allTimeData.vcpm.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>cpa <strong>{allTimeData.cpa.toFixed(2)}</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>vctr <strong>{allTimeData.vctr.toFixed(2)}%</strong></ExtraOptimizationInfo>
                            <ExtraOptimizationInfo>ROAS <strong>{(allTimeData.roas*100).toFixed(2)}%</strong></ExtraOptimizationInfo>
                        </CompareDataContainer>   
                    ) : (
                        <Loading />
                    )
                )}  
            </OptimizationContainer>
            
            {site.bid && pickDate !== 0 && (
                <EditTaboola site={site} bid={site.bid} campaignTaboolaData={campaignTaboolaData} showData={showData} taboolaInfo={taboolaInfo} idTaboola={id_campaign_taboola} updateCampaignTaboolaData={updateCampaignTaboolaData} editSiteStatusMessage={editSiteStatusMessage}/>
            )}

        </SiteRowContainer>
    )
}

export default SiteRow
