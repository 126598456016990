import styled from "styled-components"

export const AdsLine = styled.div`
    display: flex;
    background: white;
    border-radius: 5px;
    
    @media screen and (max-width: 750px) {
        flex-direction: column;
        align-items: center;
        padding: 5px;
    }
`

export const Period = styled.div`
    font-size: 15px;
    width: 70px;
    color: rgb(119 190 77);
    background: #343434;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    @media screen and (max-width: 750px) {
        border-radius: 5px;
        padding: 5px;
        background: white;
        font-size: 20px;
    }
`

export const OptimizationContainer = styled.div`
    display: flex;
    padding: 5px 0px;
    justify-content: space-between;

    @media screen and (max-width: 750px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
    }
`

export const OptimizationInfo = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-start;
    align-items: center;
    min-width: 85px;
    padding: 2px 0px;

    @media screen and (max-width: 750px) {
        padding: 4px 0px;
    }
`