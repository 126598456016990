import styled from "styled-components";

export const GraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Label = styled.label`
    font-size: 12px;
`

export const StyledText = styled('text')(({ color }) => ({
    fill: color,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 10,
}));
  