import styled from "styled-components";

export const CampaingFunnelsPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  flex-grow: 1;
  background-color: #dfdfdf;
  overflow: auto;
  padding: 15px;
  box-shadow: 0px 0px 5px 1px black;

  @media screen and (max-width: 750px) {
    padding: 10px;
    flex-wrap: nowrap
  }
`

export const HeaderAdContainer = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 1px black;

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`

export const GoBackContainer = styled.div`
  display: none;
  color: rgb(119 190 77);
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;

  @media screen and (max-width: 750px) {
    display: flex;
    padding-bottom: 5px;
  }
`

export const AdInfo = styled.div`
  display: flex;

  @media screen and (max-width: 750px) {
    padding-bottom: 10px;
  }
`

export const AdThumbnail = styled.img`
  width: 90px;
  height: 90px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media screen and (max-width: 750px) {
    width: 120px;
    height: 120px;
    border-radius: 5px;
  }
`
export const AdTitle = styled.div`
  font-size: 25px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  padding-left: 10px;

  @media screen and (max-width: 750px) {
    align-items: end;
  }
`

export const SelectPeriod = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 200px;
    padding: 5px;
    gap: 3px;
    border: 1px solid #343434;
    border-radius: 5px;
    justify-content: space-evenly;

    @media screen and (max-width: 750px) {
      max-width: 100%;
    }
`

export const Period = styled.div`
    font-size: 12px;
    padding: 2px 5px;
    color: #343434;
    background: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
    border: 1px solid #343434;


    &.active {
        color: rgb(119 190 77);
        border: 1px solid rgb(119 190 77);
        background: white;
        font-weight: 500;
    }
`
