import styled from "styled-components";

export const NavbarContainer = styled.div`
  background-color: #222222;
  display: none;
  flex-direction: column;

  @media screen and (max-width: 750px) {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 20px;
    background: transparent;
    z-index: 10;
  }
`

export const LinksContainer = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
`

export const ListItem = styled.div`
  min-width: 100px;
  
  &:hover {
    background-color: #757575;
  }

  & > a {
    border: 1px solid transparent;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0px 15px;
    text-transform: uppercase;
    justify-content: center;
    font-size: 12px;
    background: #343434;
    border-radius: 5px;
  }

  & > a:hover {
    background-color: #757575;
  }
  
  & > a.active {
    background-color: white;
    color: rgb(119 190 77);
    border: 1px solid rgb(119 190 77);

    .link-icon {
      fill: rgb(119 190 77) !important;
    }
  }
`