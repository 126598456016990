import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
  position: relative;
  top: 30px;
  overflow-x: hidden;

  @media screen and (max-width: 750px) {
    top: 100px;
  }
`

export const Header = styled.div`
  background: black;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 10;
`

export const HeaderDisapear = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
  align-items: center;

  &: hover{
    color: white
  }

  @media screen and (max-width: 750px) {
    display: none;
  }
`

export const LoginLink = styled.div`
  color: rgb(49 186 91);
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  font-weight: 200;
  transition: color 0.4s cubic-bezier(0, 0, 0.11, 1.17);

  &: hover{
    color: white
  }
`

export const Links = styled.div`
  color: rgb(49 186 91);
  font-size: 15px;
  cursor: pointer;
  padding: 20px;
  font-weight: 200;
`

export const Logo = styled.img`
  width: 200px;
`

export const Welcome = styled.video`
  width: 100%;
  height: 100%;
  opacity: 0.7;
`

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const WelcomeContainer = styled.div`
  font-size: 75px;
  color: white;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction column;
  align-items: center;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 200;
  animation: ${appear} 3.5s;

  @media screen and (max-width: 750px) {
    font-size: 36px;
    top: 15%;
    transform: translate(-50%, -15%);
  }
`

export const WelcomeText = styled.div`
  font-size: 30px;
  padding: 80px 0px;
  letter-spacing: 3px;
  font-weight: 200;
  width: 80%;

  @media screen and (max-width: 750px) {
    font-size: 13px;
    width: 100%;
    padding: 60px 0px;
    font-weight: 300;
    letter-spacing: 2px;
  }
`

export const IntegrationsContainer = styled.div`
  width: 100%;
  font-size: 24px;
  text-align: center;
  color: white;
  background: #000;
  padding-top: 15px;
  font-weight: 200;
  letter-spacing: 3px;
  z-index: 2
`

export const LogosIntContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 15px 0px 30px;
  align-items: center;
`

export const GetToKnow = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 30px;
  background-color: #111;
  align-items: center;
  color: white;
  padding: 40px 0px;
  letter-spacing: 3px;
  font-weight: 200;

  @media screen and (max-width: 750px) {
    font-size: 35px;
  }
`

export const Row = styled.div`
  display: flex;
  padding-top: 40px;
  gap: 50px;
  justify-content: center;

  @media screen and (max-width: 750px) {
    flex-direction: column-reverse;
    gap: 0px;
    align-items: center;
  }
`

export const RowReverse = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 40px;
  gap: 50px;
  justify-content: center;

  @media screen and (max-width: 750px) {
    flex-direction: column-reverse;
    gap: 0px;
    align-items: center;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  gap: 10px;

  @media screen and (max-width: 750px) {
    width: 90%;
    align-items: center;
  }
`

export const ColumnRow = styled.div`
  display: flex;
  width: 45%;
  gap: 10px;

  @media screen and (max-width: 750px) {
    width: 90%;
    justify-content: center;
  }
`

export const TitleAdv = styled.div`
  font-size: 20px;
  font-weight: 400;
  max-width: 475px;

  @media screen and (max-width: 750px) {
    text-align: center;
    font-size: 22px;
  }
`

export const TitleAdvRight = styled.div`
  font-size: 20px;
  text-align: right;
  font-weight: 400;
  max-width: 475px;

  @media screen and (max-width: 750px) {
    font-size: 22px;
    text-align: center;
  }
`

export const Text = styled.div`
  font-size: 12px;
  max-width: 475px;

  @media screen and (max-width: 750px) {
    text-align: center;
    padding-top: 5px;
    font-size: 14px;
  }
`

export const TextRight = styled.div`
  font-size: 12px;
  text-align: right;
  max-width: 475px;

  @media screen and (max-width: 750px) {
    text-align: center;
    font-size: 14px;
    padding-top: 5px;
  }
`

export const Video1 = styled.video`
  width: 450px;
  border-radius: 15px;
  align-self: flex-end;

  @media screen and (max-width: 750px) {
    width: 90%;
    align-self: center;
    margin-top: 15px;
  }
`

export const Video2 = styled.video`
  width: 450px;
  border-radius: 15px;

  @media screen and (max-width: 750px) {
    width: 90%;
    margin-top: 15px;
  }
`

export const Video3 = styled.video`
  width: 450px;
  border-radius: 15px;
  align-self: flex-end;

  @media screen and (max-width: 750px) {
    width: 90%;
    margin-top: 15px;
    align-self: center;
  }
`

export const TimeLineOnBoardContainer = styled.div`
  background: #000;
  width: 100%;
  color: white;
  letter-spacing: 3px;
  text-align: center;
  padding-top: 40px;
  font-size: 30px;

  @media screen and (max-width: 750px) {
    padding-bottom: 30px;
  }
`

export const TimeLineOnBoard = styled.div`
  display: flex;
  padding: 50px 200px;
  gap: 50px;
  overflow: auto;

  @media screen and (max-width: 750px) {
    padding: 30px 50px;
  }
`

export const Timeline = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(49 186 91 / 39%);
  position: relative;
  top: -475px;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    top: -455px;
  }
`

export const TimelineDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgb(49 186 91);
  box-shadow: 0px 0px 5px 1px rgb(49 186 91);
  position: relative;
  top: 5px;
`

export const CardOnBoard = styled.div`
  width: 300px;
  padding: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #141414;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgb(49 186 91);
  z-index: 1;
  justify-content: space-between;
`

export const CardHeader = styled.div`
  display: flex;
  gap: 20px;
`

export const CardNumber = styled.div`
  font-size: 30px;
  height: 55px;
  border-bottom: 3px solid rgb(49 186 91);
`

export const CardTitle = styled.div`
  color: rgb(49 186 91);
  font-weight: bold;
  font-size: 18px;
  width: 200px;
  text-align: start;
`

export const CardText = styled.div`
  color: white;
  text-align: left;
  padding-top: 15px;
  font-size: 12px;
`

export const CardImg = styled.img`
  width: 200px;
  padding-top: 15px;
`

export const PhoneSection = styled.div`
  padding-top: 900px;
  width: 100%;
  position: relative;
  display: flex;

  @media screen and (max-width: 750px) {
    padding-top: 500px;
  }
`

export const PhonePic = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform:  translate(-50%, -55%);
  width: 450px;
  z-index: 2;

  @media screen and (max-width: 750px) {
    width: 250px;
  }
`

interface Props {
  scrollPosition: number
}

export const PhoneAppPic1 = styled.img<Props>`
  position: absolute;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform:  translate(${props => - 55 - props.scrollPosition/25}%, ${props => - 55 - props.scrollPosition/50}%);
  width: 200px;

  @media screen and (max-width: 750px) {
    width: 120px;
  }
`

export const PhoneAppPic2 = styled.img<Props>`
  position: absolute;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform:  translate(${props => - 55 + props.scrollPosition/23}%, ${props => - 55 - props.scrollPosition/55}%);
  width: 200px;

  @media screen and (max-width: 750px) {
    width: 120px;
  }
`

export const PhoneAppPic3 = styled.img<Props>`
  position: absolute;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform:  translate(${props => - 55 - props.scrollPosition/25}%, ${props => - 55 + props.scrollPosition/45}%);
  width: 200px;

  @media screen and (max-width: 750px) {
    width: 120px;
  }
`

export const PhoneAppPic4 = styled.img<Props>`
  position: absolute;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform:  translate(${props => - 55 + props.scrollPosition/23}%, ${props => - 55 + props.scrollPosition/45}%);
  width: 200px;

  @media screen and (max-width: 750px) {
    width: 120px;
  }
`

export const WhoAreWeSection = styled.div`
  width: 100%;
  background: #111;
  font-size: 30px;
  color: white;
  text-align: center;
  padding-top: 30px;
`

export const WhoAreWeContent = styled.div`
  display: flex;
  padding: 30px 0px;
  gap: 100px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 0px;
  }
`

export const WhoAreTitle = styled.div`
  color: rgb(49 186 91);
  font-size: 30px;
  letter-spacing: 3px;
  font-weight: 300;
  padding-bottom: 15px;

  @media screen and (max-width: 750px) {
    font-size: 22px;
  }
`

export const WhoArePhrase = styled.div`
  color: white;
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 200;
`

export const WhoAreText = styled.div`
  color: white;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 200;
  padding-bottom: 30px;
  width: 400px;
`
