import { useState } from "react"
import { EditTaboolaContainer, IsActiveContainer, BidContainer, CpcValue, BidPercent, 
    PlusMinusContainer, MinusBtn, PlusBtn, CpcInputContainer, InputContainer, CpcInput, 
    CancelBtn, SendBtn} from "./styles"
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { CampaignSiteSummaryModel } from "../../../models/campaign-sites-summary";
import { CampaignTaboolaData } from "../../../models/campaign-taboola-data";
import Loading from "../../Loading/Small";
import { updateTaboolaCampaign } from "../../../services/taboola";
import { AccountTaboolaDataModel } from "../../../models/account-taboola-data";
import $ from 'jquery'

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));
const label = { inputProps: { 'aria-label': 'controlled' } };

interface Props {
    site: CampaignSiteSummaryModel
    bid: number
    campaignTaboolaData: CampaignTaboolaData
    showData: boolean
    taboolaInfo?: AccountTaboolaDataModel[]
    idTaboola: string
    updateCampaignTaboolaData: (data: any) => Promise<boolean>
    editSiteStatusMessage: (status: boolean) => void
}

const EditTaboola = ({ site, bid, campaignTaboolaData, showData, taboolaInfo, idTaboola, updateCampaignTaboolaData, editSiteStatusMessage }: Props) => {
    const [isActive, setIsActive] = useState<boolean>(site.is_active? site.is_active : false)
    const [valid, setValid] = useState<boolean>(true)
    const [loadingEdit, setLoadingEdit] = useState<boolean>(false)
    const [loadingActive, setLoadingActive] = useState<boolean>(false)

    const updateSiteActive = (e: any) => {
        setLoadingActive(true)
        const data = {
            publisher_targeting: {
                type: "EXCLUDE",
                value: campaignTaboolaData.blocked_publisher_targeting
            }
        }
        if (taboolaInfo) {
            const itemIndex = data.publisher_targeting.value.findIndex((element: any) => element === site.target);
            if (itemIndex>=0) {
                data.publisher_targeting.value.splice(itemIndex, 1)
            } else {
                data.publisher_targeting.value.push(site.target)
            }

            updateTaboolaCampaign(taboolaInfo[0].access_token, taboolaInfo[0].account_id, idTaboola, data)
            .then(resp => {
                updateCampaignTaboolaData({
                    ...campaignTaboolaData,
                    blocked_publisher_targeting: resp.data.publisher_targeting.value
                })
                setLoadingActive(false)
                setIsActive(!isActive)
                editSiteStatusMessage(true)
            })
            .catch(err => {
                editSiteStatusMessage(false)
                setLoadingActive(false)
                console.log(err)
            })
        }
    }

    const openEditCpc = () => {
        $(`#input-value-${site.id_site}`).val((bid * 100 - 100).toFixed(0))
        $(`#cpcContainer-${site.id_site}`).css('display') === 'none'? $(`#cpcContainer-${site.id_site}`).css('display', 'flex') : $(`#cpcContainer-${site.id_site}`).css('display', 'none')
        $(`#cpcContainer-${site.id_site}`).css('display') === 'flex' && $(`#input-value-${site.id_site}`).focus()
        $(`#new-value-${site.id_site}`).text(``)
        setValid(true)
    }

    const inputChange = () => {
        if(Number($(`#input-value-${site.id_site}`).val()) < -99 || Number($(`#input-value-${site.id_site}`).val()) > 100 || (Number($(`#input-value-${site.id_site}`).val())+100)/100*campaignTaboolaData.cpc < 0.05) {
            setValid(false)
            $(`#input-value-${site.id_site}`).val(bid * 100 - 100)
            $(`#new-value-${site.id_site}`).text(``)
        } else {
            const newVal = (Number($(`#input-value-${site.id_site}`).val())+100)/100*campaignTaboolaData.cpc
            $(`#new-value-${site.id_site}`).text(`(${newVal.toFixed(3)})`)
            setValid(true)
        }
    }
    
    const sendEdit = () => {
        if (valid) {
            setLoadingEdit(true)
            const data = {
                publisher_bid_modifier: {
                    values: [...campaignTaboolaData.bid_modifier]
                }
            }

            if (taboolaInfo) {
                const newBid = Number($(`#input-value-${site.id_site}`).val())/100 + 1
                const itemIndex = data.publisher_bid_modifier.values.findIndex((element: any) => element.target === site.target);
                if (itemIndex >= 0) {
                    data.publisher_bid_modifier.values[itemIndex] = {
                        target: site.target,
                        cpc_modification: newBid
                    }
                } else {
                    data.publisher_bid_modifier.values.push({
                        target: site.target,
                        cpc_modification: newBid
                    })
                }
                updateTaboolaCampaign(taboolaInfo[0].access_token, taboolaInfo[0].account_id, idTaboola, data)
                .then(resp => {
                    updateCampaignTaboolaData({
                        ...campaignTaboolaData,
                        bid_modifier: resp.data.publisher_bid_modifier.values
                    })

                    $(`#cpcContainer-${site.id_site}`).css('display', 'none')
                    setLoadingEdit(false)
                    editSiteStatusMessage(true)
                })
                .catch(err => {
                    editSiteStatusMessage(false)
                    setLoadingEdit(false)
                    console.log(err)
                })
            }
        }
    }

    return (
        <>
            <EditTaboolaContainer>
                <IsActiveContainer>
                    {!loadingActive? (
                        <GreenSwitch {...label} checked={isActive} onChange={(e) => updateSiteActive(e)} />
                    ) : (
                        <Loading />
                    )}
                </IsActiveContainer>

                <BidContainer>
                    <CpcValue onClick={() => openEditCpc()}>
                        {(campaignTaboolaData.cpc * bid).toFixed(3)}
                    </CpcValue>
                    {(bid * 100)-100>0? (
                        <BidPercent onClick={() => openEditCpc()}><span style={{color: 'green'}}>(+{((bid*100)-100).toFixed(0)+'%'})</span></BidPercent>
                    ) : (
                        (bid * 100)-100===0? (
                            <BidPercent onClick={() => openEditCpc()}>(Default)</BidPercent>
                        ) : (
                            <BidPercent onClick={() => openEditCpc()}><span style={{color: 'red'}}>({((bid*100)-100).toFixed(0)+'%'})</span></BidPercent>
                        )
                    )}
                    {showData && (
                        <PlusMinusContainer>
                            <MinusBtn><span style={{position: 'relative', bottom: '2px'}}>-</span></MinusBtn>
                            <PlusBtn>+</PlusBtn>
                        </PlusMinusContainer>
                    )}
                </BidContainer>
            </EditTaboolaContainer>

            {site && (
                <CpcInputContainer id={`cpcContainer-${site.id_site}`}>
                    {!loadingEdit? (
                        <>
                            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                Bid atual: {(campaignTaboolaData.cpc * bid).toFixed(3)} <span id={`new-value-${site.id_site}`} style={{color: '#4d4d4d', fontSize: '12px'}}></span>
                            </div>
                            <div style={{display: 'flex', gap: '5px', paddingTop: '5px'}}>
                                <InputContainer><CpcInput id={`input-value-${site.id_site}`} type="number" min={-99} max={100} onChange={() => inputChange()}/>%</InputContainer>
                                <CancelBtn onClick={() => openEditCpc()}>Cancelar</CancelBtn>
                                <SendBtn onClick={() => sendEdit()}>Enviar</SendBtn>
                            </div>
                            {!valid && (
                                <label style={{color: 'rgb(249, 96, 96)', fontSize:'10px', paddingTop: '3px'}}>*O cpc deve estar entre -99% e 100%<br></br>*O valor mínimo do cpc é 0,05</label>
                            )}
                        </>
                    ) : (
                        <Loading />
                    )}
                </CpcInputContainer>
            )}
        </>
    )
}

export default EditTaboola
