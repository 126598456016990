import styled from "styled-components";

export const CampaingsPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  background-color: #dfdfdf;
  gap: 20px;
  overflow: auto;
  padding: 10px 0px;
  box-shadow: 0px 0px 5px 1px black;

  @media screen and (max-width: 750px) {
    padding: 10px;
    padding-bottom: 50px;
  }
`
