import styled from 'styled-components'

export const ContainerNewCampaignForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 90%;
    overflow: scroll;
`

export const MessageSuccess = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 15px;
`

export const ContainerInputs = styled.div`
    borderBottom: 1px solid #dfdfdf;
    display: flex;
    justifyContent: center;
    alignItems: center;
    gap: 15px;

     @media screen and (max-width: 750px) {
        flex-direction: column;
    }
`

export const SubAccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`

export const SubAccountSelect = styled.select`
    border: 1px solid #cecece;
    border-radius: 5px;
    font-size: 14px;
    min-height: 20px;
    padding: 10px;
`

export const SubAccountOption = styled.option`

`

export const SubAccountLabel = styled.div`
    font-size: 14px;
    font-family: Roboto;
    font-weight: medium;
    margin: 0 0 10px 0;
    color: #1e1e1e;
    letter-spacing: 0px;
`

