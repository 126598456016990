import styled from "styled-components"

export const EditTaboolaContainer = styled.div`
    padding: 5px;
    border-left: 7px solid #dfdfdf;
    width: 90px;
    justify-content: center;
`

export const IsActiveContainer = styled.div`
    display: flex;
    justify-content: center;
`

export const BidContainer = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const CpcValue = styled.div`
    font-size: 14px;
    cursor: pointer;
`

export const BidPercent = styled.div` 
    display: flex;
    flex-direction: column;
    font-size: 10px;
    color: #343434;
    cursor: pointer;
`

export const PlusMinusContainer = styled.div`
    display: none;
    gap: 3px;
    padding-top: 10px;
`

export const PlusBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    background-color: #222222;
    color: rgb(119, 190, 77);
    width: 35px;
    height: 30px;
    font-size: 25px;
    cursor: pointer;
`

export const MinusBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    background-color: #222222;
    color: rgb(119, 190, 77);
    width: 35px;
    height: 30px;
    font-size: 30px;
    cursor: pointer;
`

export const CpcInputContainer = styled.div`
    display: none;
    position: absolute;
    border-radius: 5px;
    background: white;
    border: 1px solid #dfdfdf;
    padding: 5px;
    flex-direction: column;
    right: 30px;
    top: 3px;
    font-size: 14px;

    @media screen and (max-width: 750px) {
        font-size: 18px;
        padding: 10px;
    }
`

export const InputContainer = styled.div`
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 5px; 
    font-size: 14px;
`

export const CpcInput = styled.input`
    width: 35px;
    text-align: center;
    border: none;
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }

    &:focus-visible {
        outline: none;
    }

    @media screen and (max-width: 750px) {
        font-size: 16px;
    }
`

export const SendBtn = styled.button`
    background: white;
    color: rgb(119, 190, 77);
    border: 1px solid rgb(119, 190, 77);
    padding: 5px;
    width: 60px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.4s cubic-bezier(0, 0, 0.11, 1.17);

    &:hover {
        background-color: rgb(119, 190, 77);
        color: white;
    }

    @media screen and (max-width: 750px) {
        width: 70px;
        font-size: 14px;
    }
`

export const CancelBtn = styled.button`
    background: white;
    color: rgb(249, 96, 96);
    border: 1px solid rgb(249, 96, 96);
    padding: 5px;
    width: 60px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.4s cubic-bezier(0, 0, 0.11, 1.17);

    &:hover {
        background-color: rgb(249, 96, 96);
        color: white;
        width: 70px;
    }

    @media screen and (max-width: 750px) {
        width: 70px;
        font-size: 14px;
    }
`