import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  font-size: 30px;
  letter-spacing: 3px;
  padding: 30px 0px;

  @media screen and (max-width: 750px) {
    top: 100px;
  }
`

export const PlanGroupBtn = styled.div`
  width: 100px;
  display: flex;
  color: #dfdfdf;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  justify-content: center;
  padding: 5px 0px;
  cursor: pointer;

  &.active {
    border: 1px solid rgb(49 186 91);
    color: rgb(49 186 91);
  }
`

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0px;
  overflow: auto;
  gap: 10px;

  @media screen and (max-width: 750px) {
    justify-content: unset;
    padding: 50px 20px;
  }
`

export const CardPlan = styled.div`
  width: 300px;
  padding: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #141414;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgb(49 186 91);
  z-index: 1;
  justify-content: space-between;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  align-items: center;
  border-bottom: 1px solid rgb(49 186 91);
`

export const PriceImg = styled.img`
  width: 200px;
  padding: 15px 0px;
`

export const RegisterBtn = styled.div`
  width: 90%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid rgb(49 186 91);
  color: rgb(49 186 91);
  transition: color 0.4s cubic-bezier(0, 0, 0.11, 1.17);
  transition: background-color 0.4s cubic-bezier(0, 0, 0.11, 1.17);

  &:hover {
    border: 1px solid transparent;
    color: white;
    background: rgb(49 186 91);
  }
`

export const CardTitle = styled.div`
  color: rgb(49 186 91);
  font-weight: bold;
  font-size: 18px;
`

export const CardText = styled.div`
  color: white;
  width: 85%;
  display: flex;
  gap: 5px;
  align-items: center;
  letter-spacing: 1px;
  text-align: left;
  padding-top: 5px;
  font-size: 12px;

  @media screen and (max-width: 750px) {
    width: 300px;
  }
`