import { useState } from "react"
import { DateContainer, Period, SelectPeriod } from "./styles"
import FilterListIcon from '@mui/icons-material/FilterList';

interface Props {
    selected: string
    selectOrderBy: (option: string) => void
}

const FilterPicker = ({ selected, selectOrderBy }: Props) => {
    const [clicked, setClicked] = useState<boolean>(false)

    return (
        !clicked? (
            <DateContainer onClick={() => setClicked(true)}>
                <FilterListIcon className="link-data" style={{ fill: '#ffffff', width: '25px', height: '25px' }}/>
            </DateContainer>
        ) : (
            <>
                <SelectPeriod  onClick={() => setClicked(false)}>
                    <Period className={selected === 'Gasto'? 'active' : ''} onClick={() => selectOrderBy('Gasto')}>Gasto</Period>
                    <Period className={selected === 'Conversões'? 'active' : ''} onClick={() => selectOrderBy('Conversões')}>Conver.</Period>
                    <Period className={selected === 'Faturamento'? 'active' : ''} onClick={() => selectOrderBy('Faturamento')} >Fatur.</Period>
                    <Period className={selected === 'cpa'? 'active' : ''} onClick={() => selectOrderBy('cpa')} >cpa</Period>
                    <Period className={selected === 'Checkout'? 'active' : ''} onClick={() => selectOrderBy('Checkout')} >Check.</Period>
                    <Period className={selected === 'cpc'? 'active' : ''} onClick={() => selectOrderBy('cpc')}>cpc</Period>
                    <Period className={selected === 'vcpm'? 'active' : ''} onClick={() => selectOrderBy('vcpm')} >vcpm</Period>
                    <Period className={selected === 'vctr'? 'active' : ''} onClick={() => selectOrderBy('vctr')} >vctr</Period>
                    <Period className={selected === 'Clicks'? 'active' : ''} onClick={() => selectOrderBy('Clicks')} >Clicks</Period>
                </SelectPeriod>
            </>
        )
    )
}

export default FilterPicker
