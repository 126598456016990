import { AdThumbnail, AdTitle, CampaingFunnelsPageContainer, HeaderAdContainer, SelectPeriod, Period, AdInfo, GoBackContainer } from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../models/user'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import HeaderPage from './Header/index'
import { loadCampaignOptimizationData } from '../../services'
import { CampaignModel } from '../../models/campaing'
import { db } from '../../db/db'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
  user?: User []
}

const AdPageLayout = ({ user }: Props) => {
  const navigate = useNavigate()
  const location = useLocation();
  const dataLocation = location.state;

  const [pickDate, setPicDate] = useState<number>(dataLocation.pickDate)
  const [optimizationData, setOptimizationData] = useState<CampaignModel>()
  const [loadingHeader, setLoadingHeader] = useState<boolean>(true)
  const [today, setToday] = useState<boolean>(true)
  const [yesterday, setYesterday] = useState<boolean>(true)
  const [last3, setLast3] = useState<boolean>(true)
  const [last7, setLast7] = useState<boolean>(true)
  const [last15, setLast15] = useState<boolean>(false)
  const [last30, setLast30] = useState<boolean>(false)
  const [last60, setLast60] = useState<boolean>(false)
  const [last90, setLast90] = useState<boolean>(false)
  const [allTime, setAllTime] = useState<boolean>(false)

  useEffect(() => {
    const controller = new AbortController();
    if (user && user.length>0) {
      loadCampaignOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, pickDate, controller)
      .then(resp => {
        setOptimizationData(resp.data)
        setLoadingHeader(false)
      })
      .catch(err => {
        setLoadingHeader(false)
        if (err.response.status === 403) {
          db.taboola.clear()
          db.user.clear()
          navigate('/login')
        }
        console.log(err)
      })
      return () => controller.abort();
    }
  }, [user, pickDate, navigate, dataLocation])

  const selectDate = (selectedPeriod: number) => {
    setPicDate(selectedPeriod)
  }

  const showLoading = () => {
    setLoadingHeader(true)
  }

  const redirectPage = () => {
    navigate('/campaigns/' + optimizationData?.id + '/ads', {state: { campaignSummary: dataLocation.campaignSummary, pickDate: pickDate }})
}

  return (
    <CampaingFunnelsPageContainer>
      <HeaderPage campaign={optimizationData} campaignSummary={dataLocation.campaignSummary} ad={dataLocation.ad} pickDate={pickDate} loading={loadingHeader} selectDate={selectDate} showLoading={showLoading}/>
      
      <HeaderAdContainer>
        <GoBackContainer onClick={() => redirectPage()}>
            <ExpandLessIcon style={{ 
                fill: 'rgb(119 190 77)',
                width: '25px', 
                height: '25px', 
                rotate: '270deg',
                top: '50px'
            }} />
            voltar
        </GoBackContainer>
        <AdInfo style={{display: 'flex'}}>
          <AdThumbnail src={dataLocation.ad.thumbnail} />
          <AdTitle>
            {dataLocation.ad.title}
            <span style={{fontSize: '12px'}}>id: {dataLocation.ad.id_ads_taboola}</span>   
          </AdTitle>
        </AdInfo>

        <SelectPeriod>
          <Period className={today? 'active' : ''} onClick={() => setToday(!today)}>Hoje</Period>
          <Period className={yesterday? 'active' : ''} onClick={() => setYesterday(!yesterday)}>Ontem</Period>
          <Period className={last3? 'active' : ''} onClick={() => setLast3(!last3)}>3 Dias</Period>
          <Period className={last7? 'active' : ''} onClick={() => setLast7(!last7)}>7 Dias</Period>
          <Period className={last15? 'active' : ''} onClick={() => setLast15(!last15)}>15 Dias</Period>
          <Period className={last30? 'active' : ''} onClick={() => setLast30(!last30)}>30 Dias</Period>
          <Period className={last60? 'active' : ''} onClick={() => setLast60(!last60)}>60 Dias</Period>
          <Period className={last90? 'active' : ''} onClick={() => setLast90(!last90)}>90 Dias</Period>
          <Period className={allTime? 'active' : ''} onClick={() => setAllTime(!allTime)}>Vitalício</Period>
        </SelectPeriod>
      </HeaderAdContainer>

      <Outlet context={{ today, yesterday, last3, last7, last15, last30, last60, last90, allTime, dataLocation }}/>

    </CampaingFunnelsPageContainer>
  )
}

export default AdPageLayout