import Button from '../../FormElements/Button'
import { ContainerNewCampaign, HeaderNewCampaign, ContainerNewCampaignForm, MessageSuccess } from './styles'
import { User } from '../../../models/user'
import Loading from '../../Loading'

interface Props {
    user: User []
    name?: string
    id?: number
    loading: boolean
    setCampaignToDelete: (id: number, name: string) => void
    submitDelete: (id: number) => void
}

const ModalDeleteCampaign = ({ user, name, id, loading, setCampaignToDelete, submitDelete }: Props) => {

    const closeModal = () => {
        setCampaignToDelete(0, '')
    }

    return (
        <ContainerNewCampaign>
            <HeaderNewCampaign>Deletar Campanha</HeaderNewCampaign>

            {loading? (
                <Loading />
            ) : (
                <ContainerNewCampaignForm>
                    <MessageSuccess>Tem certeza que deseja deletar a campanha:</MessageSuccess>
                    <MessageSuccess><strong> {name}</strong></MessageSuccess>
                    <MessageSuccess><strong><small>Essa ação não poderá ser desfeita!</small></strong></MessageSuccess>
                    
                    <div style={{paddingTop: '5px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                        <Button type='button' size='medium' model='cancel' width='30%' text='Fechar' mobileWidth='80%' onClick={() => closeModal()} />
                        <Button type='submit' size='medium' model='confirm' width='30%' text='Deletar' mobileWidth='80%' onClick={() => id && submitDelete(id)}/>
                    </div>
                </ContainerNewCampaignForm>
            )}
        </ContainerNewCampaign>
    )
}

export default ModalDeleteCampaign