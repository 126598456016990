import { useState } from 'react'
import { useForm } from '../../../../hooks/form-hook'
import { RequireValidator } from '../../../../utils/validators'
import Button from '../../../FormElements/Button'
import FormInput from '../../../FormElements/FormInput'
import { ContainerNewCampaignForm, MessageSuccess, ContainerInputs } from './styles'
import { addCampaign } from '../../../../services'
import { User } from '../../../../models/user'
import { db } from '../../../../db/db'
import Loading from '../../../Loading'
import { CampaignModel } from '../../../../models/campaing'
import $ from 'jquery'
import { AccountTaboolaDataModel } from '../../../../models/account-taboola-data'

interface Props {
    user?: User []
    taboolaInfo?: AccountTaboolaDataModel[]
    updateCampaignArr: (campaign: CampaignModel) => void
    createCampaignStatusMessage: (valid: boolean, status?: boolean) => void
    resetSelectedAdProvider: () => void
}

const TaboolaCampaign = ({ user, taboolaInfo, updateCampaignArr, createCampaignStatusMessage, resetSelectedAdProvider }: Props) => {
    const [loading, setLoading] = useState<Boolean>(false)
    const [campaign, setCampaing] = useState<CampaignModel>()

    const [formState, inputHandler] = useForm(
        {
            name: {
                value: '',
                isValid: false,
            },
            link: {
                value: '',
                isValid: false,
            }
        },
        false
    );

    const submitCampaing = async () => {
        if (!formState.isValid) {
            createCampaignStatusMessage(false)
            return
        }
        setLoading(true)

        const campaignInfo = {
            name: formState.inputs.name.value,
            link: formState.inputs.link.value,
            sub_account: $('#sub-account-input').val(),
            ad_provider: 'meta'
        }
        console.log(campaignInfo)
        try {
            if (user){
                const { data } = await addCampaign(campaignInfo, user[0].access_token)
                setCampaing(data)
                setLoading(false)
                updateCampaignArr(data)
                createCampaignStatusMessage(true, true)
                resetSelectedAdProvider()
            }
        } catch (error: any) {
            console.log(error.response)
            setLoading(false)
            createCampaignStatusMessage(true, false)
            if (error.response.status === 403) {
                db.user.clear()
            }
        }
    }

    const closeModal = () => {
        setCampaing(undefined)
        resetSelectedAdProvider()
        const modal = document.getElementById('new-campaign')
        if (modal) {
            modal.style.display = 'none'
        }
    }

    return (
        <>
            {loading? (
                <Loading />
            ) : (
                <>
                    {campaign? (
                        <ContainerNewCampaignForm>
                            <MessageSuccess>Campanha criada com sucesso!</MessageSuccess>

                            <div style={{paddingTop: '5px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                                <Button type='button' size='medium' model='cancel' width='30%' text='Fechar' mobileWidth='80%' onClick={() => closeModal()} />
                            </div>
                        </ContainerNewCampaignForm>
                    ) : (
                        <ContainerNewCampaignForm>
                            <ContainerInputs>
                                <FormInput
                                    id='name' 
                                    type='text'
                                    label='Nome da campanha'
                                    invalidMessage='O nome da campanha é obrigatório!'
                                    validators={[RequireValidator()]}
                                    onInput={inputHandler}
                                    placeholder='Nome da campanha'
                                />

                                <FormInput
                                    id={`link`}
                                    type='text'
                                    label={`Link para redirecionamento`}
                                    invalidMessage='O link é obrigatório!'
                                    validators={[RequireValidator()]}
                                    onInput={inputHandler}
                                    placeholder='Insira o link de redirecionamento para a sua campanha'
                                />
                            </ContainerInputs>
                            
                            <div style={{paddingTop: '15px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                                <Button type='button' size='medium' model='cancel' width='30%' text='Cancelar' mobileWidth='80%' onClick={() => closeModal()} />
                                <Button type='submit' size='medium' model='confirm' width='30%' text='Enviar' mobileWidth='80%' onClick={() => submitCampaing()}/>
                            </div>
                        </ContainerNewCampaignForm>
                    )}
                </>
            )}
        </>
    )
}

export default TaboolaCampaign