import styled from "styled-components";

export const FunnelLine = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    padding: 10px;
    border-radius: 5px;
    width: 45%;
    max-width: 550px;
    @media screen and (max-width: 750px) {
        width: 95%;
    }
`

export const FunnelTitle = styled.div`
    font-size: 20px;
    font-weight: 400;
`

export const FunnelLink = styled.div`
    font-size: 12px;
    color: #757575;
    padding-top: 3px;
`

export const DataContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40%;

    @media screen and (max-width: 750px) {
        width: 50%;
    }
`

export const StepsContainer = styled.div`
    display: flex;
    padding-top: 10px;
    border-top: 1px solid #dfdfdf;
    justify-content: space-around;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width: 750px) {
        justify-content: center;
        gap: 15px;
    }
`

export const DataValues = styled.div`
    font-size: 12px;
    display: flex;
    justify-content: space-between;
`