import styled from 'styled-components'

export const ContainerNewCampaign = styled.div`
    max-height: 85vh;
    background-color: #343434;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    box-shadow: 0px 0px 5px 1px rgb(49 186 91);;  
    @media screen and (max-width: 750px) {
        width: 90%;
        max-height: 75vh;
    }
`

export const HeaderNewCampaign = styled.div`
    width: 100%;
    font-size: 5vh;
    font-weight: 200;
    height: 10vh;
    background-color: black;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: rgb(49 186 91);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ContainerRegisterWaitListForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 90%;
    overflow: scroll;
`

export const BtnAddFunnel = styled.button`
    background-color: white;
    color: #343434;
    font-size: 15px;
    border: none;
    cursor: pointer;
    height: 30px;
    border-radius: 5px;

    &:hover {
        background-color: #dfdfdf;
    }
`

export const FunnelsContainer = styled.div`
    padding-bottom: 15px;
    border-bottom: 1px solid #dfdfdf;
`

export const MessageSuccess = styled.div`
    display: flex;
    color: white;
    width: 100%;
    justify-content: center;
    padding-bottom: 15px;
`

export const TitleFunnels = styled.div`
    font-size: 3vh;
    height: 7vh;
    font-weight: 200;
    color: #343434;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const FunnelInputs = styled.div`
    display: flex;
    gap: 5px;
`
