import { useEffect, useState } from 'react'
import { useForm } from '../../../hooks/form-hook'
import { EmailValidator, RequireValidator } from '../../../utils/validators'
import Button from '../../FormElements/ButtonUnauthed'
import FormInput from '../../FormElements/FormInputUnauthed'
import { ContainerNewCampaign, HeaderNewCampaign, ContainerRegisterWaitListForm, MessageSuccess } from './styles'
import Loading from '../../Loading'
import { registerToWaitList } from '../../../services'
import { WaitListModel } from '../../../models/wait-list'

const ModalWaitList = () => {
    const [loading, setLoading] = useState<Boolean>(false)
    const [registered, setRegistered] = useState<WaitListModel>()
    const [promCode, setPromCode] = useState<string>()

    useEffect(() => {
        const str = window.location.search;
        const searchParams = new URLSearchParams(str);
        const code = searchParams.get('pi')
        setPromCode(code? code : undefined)
      }, [])

    const [formState, inputHandler] = useForm(
        {
            name: {
                value: '',
                isValid: false,
            },
            email: {
                value: '',
                isValid: false,
            },
            cel: {
                value: '',
                isValid: false,
            }
        },
        false
    );

    const submitRegisterWaitList = async () => {
        setLoading(true)
        if (!formState.isValid) {
            alert('Preencha o formulário com dados válidos')
            return
        }
        console.log(promCode)
        const clientData = {
            name: formState.inputs.name.value,
            email: formState.inputs.email.value,
            cel: formState.inputs.cel.value,
            prom_code: promCode
        }
        
        registerToWaitList(clientData)
        .then(resp => {
            console.log(resp)
            setRegistered(resp.data)
            setLoading(false)
        })
        .catch(err => {
            alert('Email já cadastrado à lista de espera!')
            closeModal()
            setLoading(false)
            console.log(err)
        })
    }

    const closeModal = () => {
        const modal = document.getElementById('wait-list')
        if (modal) {
            modal.style.display = 'none'
        }
    }

    return (
        <ContainerNewCampaign>
            <HeaderNewCampaign>Lista de Espera</HeaderNewCampaign>

            {loading? (
                <Loading />
            ) : (
                <>
                    {registered? (
                        <ContainerRegisterWaitListForm>
                            <MessageSuccess>Cadastro realizado com sucesso!</MessageSuccess>

                            <small><strong style={{color: 'white', textAlign: 'center'}}>Entraremos em contato em breve!</strong></small>
                            
                            <div style={{paddingTop: '15px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                                <Button type='button' size='medium' model='confirm' width='30%' text='Fechar' mobileWidth='80%' onClick={() => closeModal()} />
                            </div>
                        </ContainerRegisterWaitListForm>
                    ) : (
                        <ContainerRegisterWaitListForm>
                            <div style={{borderBottom: '1px solid rgb(119, 190, 77)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <FormInput
                                    id='name' 
                                    type='text'
                                    label='Nome'
                                    invalidMessage='O nome é obrigatório!'
                                    validators={[RequireValidator()]}
                                    onInput={inputHandler}
                                    placeholder='ex: João Carlos de Jesus'
                                />

                                <FormInput
                                    id='email'
                                    type='text'
                                    label={`Email`}
                                    invalidMessage='O email é obrigatório!'
                                    validators={[RequireValidator(), EmailValidator()]}
                                    onInput={inputHandler}
                                    placeholder='ex: oracclum@oracclum.com'
                                />

                                <FormInput
                                    id='cel'
                                    type='text'
                                    label={`Celular`}
                                    invalidMessage='O telefone para contato é obrigatório!'
                                    validators={[RequireValidator()]}
                                    onInput={inputHandler}
                                    placeholder='ex: (11) 99999-9999'
                                />
                            </div>
                            
                            <div style={{paddingTop: '15px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                                <Button type='button' size='medium' model='cancel' width='30%' text='Cancelar' mobileWidth='80%' onClick={() => closeModal()} />
                                <Button type='submit' size='medium' model='confirm' width='30%' text='Enviar' mobileWidth='80%' onClick={() => submitRegisterWaitList()}/>
                            </div>
                        </ContainerRegisterWaitListForm>
                    )}
                </>
            )}
        </ContainerNewCampaign>
    )
}

export default ModalWaitList