import { Container, Title, GetToKnow, TitleAdv, Text, Header, Logo } from './styles'
import { useNavigate } from 'react-router-dom'

const PoliciesPage = () => {
  const navigate = useNavigate()

  return (
    <Container>
    <Header>
      <Logo onClick={() => navigate('/landing')}>Orac<span style={{color: "rgb(119 190 77)"}}>clum</span></Logo>
    </Header>

    <GetToKnow>
      <Title>Política de Privacidade</Title>
     
      <Text>Última atualização: 03/05/2024</Text>
      <Text>Esta Política de Privacidade descreve como Oracclum Company LTDA coleta, usa e compartilha informações pessoais quando você utiliza nosso software de trackeamento de vendas online.</Text>

      <TitleAdv>Informações que Coletamos</TitleAdv>
      <Text>Quando você utiliza o Serviço, podemos coletar as seguintes informações:</Text>
      <Text>
        - Informações de contato, como nome, endereço de e-mail, número de telefone; <br></br>
        - Informações de pagamento, como detalhes do cartão de crédito (processadas por um provedor de pagamento terceirizado e não armazenadas em nossos servidores);<br></br>
        - Informações de uso do Serviço, como registros de atividade, dados de acesso e informações sobre o dispositivo;<br></br>
        - Outras informações que você nos fornece voluntariamente ao usar o Serviço.
      </Text>

      <TitleAdv>Como Usamos Suas Informações</TitleAdv>
      <Text>Utilizamos as informações coletadas para:</Text>
      <Text>
        - Fornecer e manter o Serviço; <br></br>
        - Processar transações e pagamentos;<br></br>
        - Personalizar sua experiência e oferecer suporte personalizado;<br></br>
        - Analisar o uso do Serviço e melhorar nossos produtos e serviços;<br></br>
        - Cumprir obrigações legais e proteger nossos direitos.
      </Text>

      <TitleAdv>Compartilhamento de Informações</TitleAdv>
      <Text>Podemos compartilhar suas informações pessoais com:</Text>
      <Text>
        - Prestadores de serviços terceirizados que nos ajudam a fornecer o Serviço;<br></br>
        - Parceiros de negócios, afiliados e outros terceiros para fins comerciais legítimos;<br></br>
        - Autoridades governamentais quando exigido por lei ou em resposta a processos legais.
      </Text>

      <TitleAdv>Seus Direitos de Privacidade</TitleAdv>
      <Text>Você tem o direito de:</Text>
      <Text>
        - Acessar, corrigir ou excluir suas informações pessoais;<br></br>
        - Restringir ou objetar o processamento de suas informações pessoais;<br></br>
        - Solicitar a portabilidade de suas informações pessoais.
      </Text>

      <TitleAdv>Segurança de Dados</TitleAdv>
      <Text>Implementamos medidas de segurança adequadas para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.</Text>

      <TitleAdv>Alterações a Esta Política de Privacidade</TitleAdv>
      <Text>
        Podemos atualizar nossa Política de Privacidade periodicamente. Recomendamos que você reveja esta página regularmente para estar ciente de quaisquer alterações. 
        A continuação do uso do Serviço após a publicação de alterações nesta Política de Privacidade constituirá sua aceitação dessas alterações.
      </Text>

      <TitleAdv>Contate-nos</TitleAdv>
      <Text>Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco pelo e-mail: oracclum@gmail.com.</Text>
    </GetToKnow>
  </Container>
  )
}

export default PoliciesPage;