import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    height: 10vh;
  }
`

export const HeaderTitle = styled.div`
  font-size: 4vh;
  color: #343434;
  font-weight: 200;
`