/* eslint-disable react-hooks/exhaustive-deps */
import { CampaingSitesPageContainer, TabsContainer, ContentContainer, Tab, AdsContainer} from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../../models/user'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { loadCampaignSitesOptimizationData } from '../../../services'
import Loading from '../../../components/Loading'
import TimePicker from '../../../components/TimePicker'
import { db } from '../../../db/db'
import { CampaignSiteSummaryModel } from '../../../models/campaign-sites-summary'
import SiteRow from '../../../components/SiteRow'
import { AccountTaboolaDataModel } from '../../../models/account-taboola-data'
import FilterPicker from '../../../components/FilterPicker'

interface Props {
  user?: User []
  taboolaInfo?: AccountTaboolaDataModel[]
}

const CampaingSitesPage = ({ user, taboolaInfo }: Props) => {
  const navigate = useNavigate()

  const [campaignSites, setCampaignSites] = useState<CampaignSiteSummaryModel[]>()
  const [loadingSites, setLoadingSites] = useState<boolean>(true)

  const { pickDate, dataLocation, loading, campaignTaboolaData, updateCampaignTaboolaData, editSiteStatusMessage } = useOutletContext<any>();
  const [orderBy, setOrderBy] = useState<string>('Gasto')

  const [today, setToday] = useState<boolean>(true)
  const [yesterday, setYesterday] = useState<boolean>(true)
  const [last3, setLast3] = useState<boolean>(true)
  const [last7, setLast7] = useState<boolean>(true)
  const [last15, setLast15] = useState<boolean>(false)
  const [last30, setLast30] = useState<boolean>(false)
  const [last60, setLast60] = useState<boolean>(false)
  const [last90, setLast90] = useState<boolean>(false)
  const [allTime, setAllTime] = useState<boolean>(false)

  useEffect(() => {
    const controller = new AbortController();
    if (user && user.length>0) {
      loadCampaignSitesOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, pickDate, controller)
      .then(resp => {
        const allSites = resp.data.map(item => {
          const siteBid = campaignTaboolaData.bid_modifier.find((element: any) => element.target === item.target);
          const isActive = campaignTaboolaData.blocked_publisher_targeting.find((element: any) => element === item.target)
          item.bid = siteBid? siteBid.cpc_modification : 1
          item.is_active = isActive? false : true
          return item
        })
        console.log(allSites)
        setCampaignSites(allSites)
        setLoadingSites(false)
      })
      .catch(err => {
        console.log(err)
        if (err.response.status === 403) {
          db.user.clear()
          db.taboola.clear()
          navigate('/login')
        }
      }) 
    }
  }, [user, pickDate, dataLocation, navigate, campaignTaboolaData])

  useEffect(() => {
    if (campaignSites) {
      let arrToOrder = [...campaignSites]
      arrToOrder = orderSites(orderBy, arrToOrder)

      setCampaignSites(arrToOrder)
    }
  }, [orderBy])

  const orderSites = (orderParam: string, arrToOrder: CampaignSiteSummaryModel[]) => {
    if (orderParam === 'Gasto') {
      return arrToOrder.sort((a, b) => b.summary.expenses - a.summary.expenses);
    }
    else if (orderParam === 'Conversões') {
      return arrToOrder.sort((a, b) => b.summary.sales - a.summary.sales);
    }
    else if (orderParam ==='Faturamento') {
      return arrToOrder.sort((a, b) => b.summary.revenue - a.summary.revenue);
    }
    else if (orderParam === 'cpa') {
      return arrToOrder.sort((a, b) => b.summary.cpa - a.summary.cpa);
    }
    else if (orderParam === 'Checkout') {
      return arrToOrder.sort((a, b) => b.summary.checkout - a.summary.checkout);
    }
    else if (orderParam === 'cpc') {
      return arrToOrder.sort((a, b) => b.summary.cpc - a.summary.cpc);
    }
    else if (orderParam === 'vcpm') {
      return arrToOrder.sort((a, b) => b.summary.vcpm - a.summary.vcpm);
    }
    else if (orderParam === 'vctr') {
      return arrToOrder.sort((a, b) => b.summary.vctr - a.summary.vctr);
    }
    else {
      return arrToOrder.sort((a, b) => b.summary.clicks - a.summary.clicks);
    }
  }

  const selectCompare = (period: string, value: boolean) => {
    if (period === 'today') setToday(value)
    else if (period === 'yesterday') setYesterday(value)
    else if (period === 'last3') setLast3(value)
    else if (period === 'last7') setLast7(value)
    else if (period === 'last15') setLast15(value)
    else if (period === 'last30') setLast30(value)
    else if (period === 'last60') setLast60(value)
    else if (period === 'last90') setLast90(value)
    else if (period === 'allTime') setAllTime(value)
  }

  const redirectPage = (tab: string) => {
    navigate('/campaigns/' + dataLocation.campaignSummary.id + `/${tab}`, {state: { campaignSummary: dataLocation.campaignSummary }} )
  }

  const selectClick = (selectedPeriod: number) => {

  }

  const selectOrderBy = (option: string) => {
    setOrderBy(option)
  }

  return (
    <CampaingSitesPageContainer>
      <ContentContainer>
        <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px', width: '100%'}}>
          <TabsContainer>
              <Tab onClick={() => redirectPage('funnels')}>Funis</Tab>
              <Tab  onClick={() => redirectPage('ads')}>Ads</Tab>
              <Tab className='active'>Sites</Tab>
          </TabsContainer>

          <div style={{display: 'flex', gap: '5px'}}>
            <FilterPicker selected={orderBy} selectOrderBy={selectOrderBy}/>

            <TimePicker 
              selected={pickDate} 
              selectClick={selectClick}
              compareOptions={true}
              selectToCompare={selectCompare}
              today={today}
              yesterday={yesterday}
              last3={last3}
              last7={last7}
              last15={last15}
              last30={last30}
              last60={last60}
              last90={last90}
              allTime={allTime}
            />
          </div>
        </div>

        <AdsContainer>
        {loadingSites || loading? (
          <Loading />
        ) : (
          campaignSites && campaignSites.length > 0? (
            campaignSites.map((item, index) => (
            <SiteRow key={index} user={user} site={item} pickDate={pickDate} 
            today={today} yesterday={yesterday} last3={last3} last7={last7} last15={last15} 
            last30={last30} last60={last60} last90={last90} allTime={allTime} 
            campaignTaboolaData={campaignTaboolaData} taboolaInfo={taboolaInfo} 
            id_campaign_taboola={dataLocation.campaignSummary.id_campaign_taboola}
            updateCampaignTaboolaData={updateCampaignTaboolaData} editSiteStatusMessage={editSiteStatusMessage}/>
          ))) : (
            'Nenhum click registrado no periodo selecionado.'
          )
        )}
        </AdsContainer>
      </ContentContainer>
    </CampaingSitesPageContainer>
  )
}

export default CampaingSitesPage