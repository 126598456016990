import { CampaignModel } from '../../../models/campaing'
import { HeaderContainer, HeaderTitle, GoBackContainer, ContainerSummary, SummaryData, SummaryColumn, Tab, TabsContainer } from './styles'
import $ from 'jquery'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import { AdsSummaryModel } from '../../../models/ads-summary';
import { CampaignSummaryModel } from '../../../models/campaing-summary';
import Loading from '../../../components/Loading/Small';

interface Props {
    campaign?: CampaignModel
    ad?: AdsSummaryModel
    campaignSummary: CampaignSummaryModel
    pickDate: number
    loading: boolean
    selectDate: (period: number) => void
    showLoading: () => void
}

const HeaderPage = ({campaign, ad, campaignSummary, pickDate, loading, selectDate, showLoading}: Props) => {
    const navigate = useNavigate()
    const changeTabs = (period: number, tab: string) => {
        selectDate(period)
        showLoading()
        $(`#today-tab`).removeClass('active')
        $(`#yesterday-tab`).removeClass('active')
        $(`#3-days-tab`).removeClass('active')
        $(`#7-days-tab`).removeClass('active')
        $(`#15-days-tab`).removeClass('active')
        $(`#30-days-tab`).removeClass('active')
        $(`#60-days-tab`).removeClass('active')
        $(`#90-days-tab`).removeClass('active')
        $(`#all-time-tab`).removeClass('active')
        $(`#${tab}`).addClass('active')
    }

    const redirectPage = () => {
        navigate('/campaigns/' + campaign?.id + '/ads', {state: { campaignSummary: campaignSummary, pickDate: pickDate }})
    }

    return(
        <HeaderContainer>
            <div>
                <GoBackContainer onClick={() => redirectPage()}>
                    <ExpandLessIcon style={{ 
                        fill: 'rgb(119 190 77)',
                        width: '25px', 
                        height: '25px', 
                        rotate: '270deg',
                        top: '50px'
                    }} />
                    voltar
                </GoBackContainer>
                <HeaderTitle>{campaign?.name}</HeaderTitle>
            </div>

            <ContainerSummary>
                {loading? (
                    <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center'}}>
                        <Loading />
                    </div>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '5px'}}>
                        <SummaryColumn>
                            <SummaryData>Gasto: <strong>$ {campaign?.summary?.expenses.toFixed(2).replace('.', '.')}</strong></SummaryData>
                            <SummaryData>Fatur.: <strong>$ {campaign?.summary?.revenue.toFixed(2).replace('.', '.')}</strong></SummaryData>
                            <SummaryData>Clicks: <strong>{campaign?.summary?.clicks}</strong></SummaryData>
                            <SummaryData>Checkout: <strong>{campaign?.summary?.checkout}</strong></SummaryData>
                            <SummaryData>Conversões: <strong>{campaign?.summary?.sales}</strong></SummaryData>
                        </SummaryColumn>

                        <SummaryColumn>
                            <SummaryData>ROAS: <strong>{campaign?.summary && (campaign.summary.roas*100).toFixed(2).replace('.', '.')}%</strong></SummaryData>
                            <SummaryData>cpc: <strong>{campaign?.summary?.cpc.toFixed(3).replace('.', '.')}</strong></SummaryData>
                            <SummaryData>vcpm: <strong>{campaign?.summary?.vcpm.toFixed(2).replace('.', '.')}</strong></SummaryData>
                            <SummaryData>cpa: <strong>{campaign?.summary?.cpa.toFixed(2).replace('.', '.')}</strong></SummaryData>
                            <SummaryData>vctr: <strong>{campaign?.summary?.vctr.toFixed(2).replace('.', '.')}%</strong></SummaryData>
                        </SummaryColumn>
                    </div>
                )}
                
                <TabsContainer>
                    <Tab id={`today-tab`} className={pickDate === 0? 'active' : ''} onClick={() => changeTabs(0, `today-tab`) }>Hoje</Tab>
                    <Tab id={`yesterday-tab`} className={pickDate === 1? 'active' : ''} onClick={() => changeTabs(1, 'yesterday-tab')}>Ontem</Tab>
                    <Tab id={`3-days-tab`} className={pickDate === 3? 'active' : ''} onClick={() => changeTabs(3, '3-days-tab') }>3 dias</Tab>
                    <Tab id={`7-days-tab`} className={pickDate === 7? 'active' : ''} onClick={() => changeTabs(7, '7-days-tab') }>7 dias</Tab>
                    <Tab id={`15-days-tab`} className={pickDate === 15? 'active' : ''} onClick={() => changeTabs(15, '15-days-tab') }>15 dias</Tab>
                    <Tab id={`30-days-tab`} className={pickDate === 30? 'active' : ''} onClick={() => changeTabs(30, '30-days-tab') }>30 dias</Tab>
                    <Tab id={`60-days-tab`} className={pickDate === 60? 'active' : ''} onClick={() => changeTabs(60, '60-days-tab') }>60 dias</Tab>
                    <Tab id={`90-days-tab`} className={pickDate === 90? 'active' : ''} onClick={() => changeTabs(90, '90-days-tab') }>90 dias</Tab>
                    <Tab id={`all-time-tab`} className={pickDate === 500? 'active' : ''} onClick={() => changeTabs(500, 'all-time-tab') }>Vitalício</Tab>
                </TabsContainer>
            </ContainerSummary>
        </HeaderContainer>
    )
}

export default HeaderPage