import { Container, Header, Logo, GetToKnow, Title, TitleAdv, Text } from './styles'
import { useNavigate } from 'react-router-dom'

const UseTermsPage = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <Header>
        <Logo onClick={() => navigate('/landing')}>Orac<span style={{color: "rgb(119 190 77)"}}>clum</span></Logo>
      </Header>

      <GetToKnow>
        <Title>Termos de Uso</Title>
       

       
        <Text>Última atualização: 03/05/2024</Text>

        <Text>Por favor, leia estes Termos de Uso cuidadosamente antes de usar o software de trackeamento de vendas online operado por Oracclum Company LTDA.</Text>

        <TitleAdv>Aceitação dos Termos</TitleAdv>
      
        <Text>Ao acessar ou usar o Serviço, você concorda em ficar vinculado a estes Termos. Se você discordar de qualquer parte dos termos, não poderá acessar o Serviço.</Text>

        <TitleAdv>Uso do Serviço</TitleAdv>
        
       <Text>
          O Serviço destina-se apenas a fins informativos e de rastreamento de vendas online. Você concorda em usar o Serviço por sua própria conta e risco.
          Você não pode usar o Serviço de qualquer forma que viole quaisquer leis ou regulamentos aplicáveis.
          Você é responsável por manter a segurança de sua conta e senha de acesso ao Serviço.
        </Text>

        <TitleAdv>Propriedade Intelectual</TitleAdv>

        <Text>
          Todo o conteúdo do Serviço, incluindo mas não se limitando a textos, gráficos, logotipos, ícones e imagens, é de propriedade exclusiva de Oracclum Company LTDA ou de seus licenciadores.
          Você concorda em não reproduzir, distribuir, modificar, exibir ou transmitir qualquer parte do conteúdo do Serviço sem autorização prévia por escrito.
        </Text>

        <TitleAdv>Limitação de Responsabilidade</TitleAdv>

        <Text>
          O uso do Serviço é por sua conta e risco. O Serviço é fornecido "como está" e "conforme disponível", sem garantias de qualquer tipo.
          Em nenhuma circunstância Oracclum Company LTDA, seus diretores, funcionários, afiliados ou agentes serão responsáveis por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais decorrentes do uso ou incapacidade de usar o Serviço.
        </Text>

        <TitleAdv>Alterações nos Termos de Uso</TitleAdv>

        <Text>Reservamo-nos o direito de atualizar ou modificar estes Termos de Uso a qualquer momento, sem aviso prévio. Recomendamos que você revise periodicamente os Termos de Uso para estar ciente de quaisquer alterações.</Text>

        <TitleAdv>Contate-nos</TitleAdv>

        <Text> Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco pelo e-mail oracclum@gmail.com.</Text>
      </GetToKnow>
    </Container>
  )
}

export default UseTermsPage;