import { Container, Welcome, GetToKnow, Row, Column, TitleAdv,
  Text, RowReverse, ColumnRow, TextRight, TitleAdvRight, Video1, Video2, 
  Video3, Header, Links, Logo, LoginLink, WelcomeContainer, WelcomeText, 
  IntegrationsContainer, LogosIntContainer, TimeLineOnBoardContainer, 
  TimeLineOnBoard, CardOnBoard, CardHeader, CardNumber, CardTitle, CardImg, 
  CardText, Timeline, TimelineDot, PhoneSection,
  PhonePic, PhoneAppPic1, PhoneAppPic2, PhoneAppPic3, PhoneAppPic4,
  WhoAreWeSection, WhoAreTitle, WhoAreText, WhoAreWeContent,
  HeaderDisapear} from './styles'

import welcome from '../../assets/parte1.mp4'
import vid1 from '../../assets/1ponto.mp4'
import vid2 from '../../assets/2ponto.mp4'
import vid3 from '../../assets/3ponto.mp4'
import { useNavigate } from 'react-router-dom'
import ModalWaitList from '../../components/Modals/WaitList'
import { useState } from 'react'
import LogoPic from '../../assets/logo.png'
import payt from '../../assets/payt.png'
import taboola from '../../assets/taboola.png'
import cartPanda from '../../assets/cartPanda.png'
import onboard1 from '../../assets/onboard1.png'
import onboard2 from '../../assets/onboard2.png'
import onboard3 from '../../assets/onboard3.png'
import onboard4 from '../../assets/onboard4.png'
import onboard5 from '../../assets/onboard5.png'
import phone from '../../assets/celular.png'
import cel1 from '../../assets/appCelular1.png'
import cel2 from '../../assets/appCelular2.png'
import cel3 from '../../assets/appCelular3.png'
import cel4 from '../../assets/appCelular4.png'
import PriceSection from './PriceSection'

const LandingPage = () => {
  const navigate = useNavigate()
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  const handleScroll = (e: any) => {
    const element = document.getElementById('ref-container')
    if (element) setScrollPosition(element.scrollTop)
  }

  const openWaitListModal = () => {
    console.log('helllo')
    const modal = document.getElementById('wait-list')
    if (modal) {
      modal.style.display = 'flex'
    }
  }

  return (
    <>
      <Header>
        <Logo src={LogoPic}/>
        <HeaderDisapear>
          <LoginLink onClick={() => document.getElementById('onboarding')?.scrollIntoView({behavior: "smooth"})}>Integração</LoginLink>
          <LoginLink onClick={() => document.getElementById('about')?.scrollIntoView({behavior: "smooth"})}>Sobre</LoginLink>
          <LoginLink onClick={() => document.getElementById('plans')?.scrollIntoView({behavior: "smooth"})}>Planos</LoginLink>
        </HeaderDisapear>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <LoginLink onClick={() => openWaitListModal()}>Cadastre-se</LoginLink>
          <LoginLink onClick={() => navigate('/login')}>Login</LoginLink>
        </div>
      </Header>
      <Container id='ref-container' onScroll={(e) => handleScroll(e)}>
        <Welcome src={welcome} autoPlay muted loop playsInline/>
        <WelcomeContainer>
          Bem-vindo
          <WelcomeText>
            Oracclum - Analise dados verdadeiros e conheça o impacto de um trackeamento de qualidade na sua operação
          </WelcomeText>
        </WelcomeContainer>

        <IntegrationsContainer>
          Integrações:
          <LogosIntContainer>
            <img src={taboola} alt='Logo Taboola' style={{width: '110px', height: '30px', borderRadius: '5px'}}/>
            <img src={payt} alt='Logo Payt' style={{width: '75px'}}/>
            <img src={cartPanda} alt='Logo CartPanda' style={{width: '55px', borderRadius: '5px'}}/>
          </LogosIntContainer>
        </IntegrationsContainer>

        <GetToKnow>
          Conheça o Oracclum

          <RowReverse>
            <ColumnRow>
              <Video1 src={vid1} autoPlay muted loop playsInline/>
            </ColumnRow>

            <Column>
              <TitleAdvRight>Maximize Seus Resultados</TitleAdvRight>
              <TextRight>
                Com o Oracclum, você não precisa mais adivinhar.
                Nosso poderoso trackeador de clicks oferece insights em tempo real sobre o desempenho das suas campanhas. 
                Deixe a incerteza para trás e comece a tomar decisões informadas para maximizar seus resultados.
              </TextRight>
            </Column>
          </RowReverse>

          <Row>
            <Column>
              <Video2 src={vid2} autoPlay muted loop playsInline/>
            </Column>

            <Column>
              <TitleAdv>Otimização Contínua</TitleAdv>
              <Text>
                O mundo do marketing digital é dinâmico, e suas campanhas devem acompanhar o ritmo. 
                Com o Oracclum, você pode otimizar suas estratégias em tempo real. Identifique oportunidades, 
                ajuste sua abordagem e mantenha-se à frente da concorrência.
              </Text>
            </Column>
          </Row>

          <RowReverse>
            <ColumnRow>
              <Video3 src={vid3} autoPlay muted loop playsInline/>
            </ColumnRow>

            <Column>
              <TitleAdvRight>Fácil de Usar, Poderoso em Resultados</TitleAdvRight>
              <TextRight>
                Não importa o seu nível de experiência em marketing digital, nossa plataforma foi projetada para ser intuitiva e acessível. 
                Comece a rastrear seus clicks em poucos minutos e experimente o impacto imediato na sua performance.
              </TextRight>
            </Column>
          </RowReverse>
        </GetToKnow>

        <TimeLineOnBoardContainer id='onboarding'>
          Como será sua integração

          <TimeLineOnBoard>
            <CardOnBoard>
              <CardHeader>
                <CardNumber>01</CardNumber>
                <CardTitle>Cadastro</CardTitle>
              </CardHeader>

              <CardText>
                Nosso serviço é totalmente personalizado para atender a sua demanda, por isso, nos organizamos por uma lista de espera.
                Ao se cadastrar, assim que possível um de nossos colaboradores entrará em contato para iniciarmos uma conversa e então marcaremos
                uma data para fazermos o primeiro onboarding.
              </CardText>
              <CardImg src={onboard1} />
            </CardOnBoard>

            <CardOnBoard>
              <CardHeader>
                <CardNumber>02</CardNumber>
                <CardTitle>Apresentação</CardTitle>
              </CardHeader>
              
              <CardText>
                Nesse passo, vamos fazer uma call e nela iremos te apresentar nosso produto.
                Entraremos em nossa aplicação e mostraremos passo a passo tudo que é possível fazer quando se é um cliente Oracclum.
                Mostraremos todas vantagens e também tiraremos todas as suas dúvidas.
              </CardText>
              <CardImg src={onboard2} />
            </CardOnBoard>

            <CardOnBoard>
              <CardHeader>
                <CardNumber>03</CardNumber>
                <CardTitle>Coleta de dados</CardTitle>
              </CardHeader>
              
              <CardText>
                Após demonstrarmos como se utiliza nosso software, iremos ouvir todas as suas demandas e entender o funcionamento da sua operação.
                Para que dessa forma possamos desenvolver o que será ideal para você e sua empresa.
              </CardText>
              <CardImg src={onboard3} />
            </CardOnBoard>

            <CardOnBoard>
              <CardHeader>
                <CardNumber>04</CardNumber>
                <CardTitle>Desenvolvimento e integração personalizados</CardTitle>
              </CardHeader>
              
              <CardText>
                Com os dados da sua operação em mãos, nosso time irá desenvolver scripts totalmente personalizados para garantir que nosso trackeamento
                funcionará de maneira integral e após esse processo iremos marcar um novo onboarding para instruí-lo em como funcionará cada passo e onde
                os scripts devem ser colocados.
              </CardText>
              <CardImg src={onboard4} />
            </CardOnBoard>
            <CardOnBoard>
              <CardHeader>
                <CardNumber>05</CardNumber>
                <CardTitle>Facilidade, produtividade e escalabilidade</CardTitle>
              </CardHeader>
              
              <CardText>
                Integração 100% realizada é só desfrutar do Oracclum e seus benefícios, como ter atualizações em tempo real de todos os seus gastos, faturamento,
                conversões e todos os parâmetros interessantes para você, e o melhor de tudo, nosso aplicativo permite que você analise e tome ações com seu celular
                onde quer que você esteja.
              </CardText>
              <CardImg src={onboard5} />
            </CardOnBoard>
          </TimeLineOnBoard>
          <Timeline>
            <TimelineDot style={{right: '5px'}}/>
            <TimelineDot style={{left: '5px'}}/>
          </Timeline>

          <span style={{fontSize: '18px', padding: '0px 10px'}}>Tenha o controle das suas operações na palma da sua mão.</span>
        </TimeLineOnBoardContainer>

        <PhoneSection>
          <PhonePic src={phone}/>
          <PhoneAppPic1 src={cel1} scrollPosition={scrollPosition}/>
          <PhoneAppPic2 src={cel2} scrollPosition={scrollPosition}/>
          <PhoneAppPic3 src={cel3} scrollPosition={scrollPosition}/>
          <PhoneAppPic4 src={cel4} scrollPosition={scrollPosition}/>
        </PhoneSection>

        <WhoAreWeSection id='about'>
          Sobre o Oracclum

          <WhoAreWeContent>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div>
                <WhoAreTitle>Missão</WhoAreTitle>
                <WhoAreText>
                  A captura integral e o tratamento refinado dos dados afim de otimizar a alocação de recursoso no marketing digital.
                </WhoAreText>
              </div>

              <div>
                <WhoAreTitle>Visão</WhoAreTitle>
                <WhoAreText>
                  Ser uma empresa digital nacional, com reconhecimento e atuação globais. Com excelência em serviços ao cliente, produtos e processos administrativos.              
                </WhoAreText>
              </div>

              <div>
                <WhoAreTitle>Propósito</WhoAreTitle>
                <WhoAreText>
                  Otimização eficiente e escalabilidade.
                </WhoAreText>
              </div>
            </div>

            <div style={{display: 'flex', flexDirection:'column', alignItems: 'center'}}>
              <WhoAreTitle>Posicionamento estratégico</WhoAreTitle>
              <WhoAreText>
                Para o mercado de marketing digital, que busca facilidade para analisar e otimizar suas campanhas, evitar desperdício atraves da alocação correta de recursos e assim escalar sua operação.
                <br /> <br /> 
                O Oracclum oferece um sistema de coleta de dados profissional e um serviço de integração personalizado.
                Mais que qualquer outro competidor, o Oracclum está próximo dos seus clientes, conhece suas necessidades e assim alcançou a melhor UX/Ui do mercado.
              </WhoAreText>
            </div>
          </WhoAreWeContent>
        </WhoAreWeSection>

        <PriceSection id='plans' openWaitListModal={openWaitListModal}/>

        <div style={{display: 'flex', background: 'black', justifyContent: 'center'}}>
          <Links onClick={() => navigate('/policies')}>Politicas de privacidade</Links>
          <Links onClick={() => navigate('/use-terms')}>Termos de uso</Links>
        </div>
        <div style={{display: 'flex', background: 'black', justifyContent: 'center'}}>
          <Links>Email: oracclum@gmail.com | Tel: (35) 9 9742-9702</Links>
        </div>
        <div style={{display: 'flex', background: 'black', justifyContent: 'center'}}>
          <Links>Oracclum Company LTDA | CNPJ: 54931465/0001-95</Links>
        </div>
      </Container>
      <div id='wait-list' className='modal'>
        <ModalWaitList/>
      </div>
    </>
  )
}

export default LandingPage;