import styled from "styled-components";

export const DateContainer = styled.div`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    background: #343434;
    border-radius: 5px;
    cursor: pointer; 
    height: fit-content;
    
    &:hover {
        background: white;
        border: 1px solid rgb(119 190 77);

        .link-data {
            fill: rgb(119 190 77) !important;
          }
    }
`

export const DateImg = styled.img`
    width: 50px;
`

export const SelectPeriod = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 190px;
    padding: 5px;
    gap: 5px;
    background: #343434;
    border-radius: 5px;
    height: fit-content;
    justify-content: space-evenly;

    @media screen and (max-width: 750px) {
        position: absolute;
        top: 280px;
        right: 30px;
        z-index: 10;
    }
`

export const Period = styled.div`
    font-size: 12px;
    padding: 5px;
    color: #343434;
    background: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
    
    &.active {
        background: rgb(119 190 77);
        color: white;
        font-weight: 500;
    }
`

export const SelectComparePeriod = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 200px;
    padding: 5px;
    gap: 3px;
    border: 1px solid #343434;
    border-radius: 5px;
    justify-content: space-evenly;
    background: white;

    @media screen and (max-width: 750px) {
      max-width: 100%;
    }
`

export const ComparePeriod = styled.div`
    font-size: 12px;
    padding: 4px;
    color: #343434;
    background: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
    border: 1px solid #343434;


    &.active {
        color: rgb(119 190 77);
        border: 1px solid rgb(119 190 77);
        background: white;
    }
`

export const TextCompare = styled.div`
    font-size: 15px;
    width: 100%;
    color: #343434;
`

export const Text = styled.div`
    font-size: 15px;
    color: white;
    width: 100%;
`